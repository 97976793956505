import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthProvider as AuthProviderT } from '@firebase/auth-types'
import AuthError from '../../../utils/AuthError'
import SignupView from '../../../components/Signup'
import { SessionContext } from '../../../context/SessionContext'
import getApp, { functions } from '../../../firebase'
import firebase from 'firebase/compat/app'
import { View } from 'native-base'
import { useTranslation } from 'react-i18next'
import Logo from '../../../components/Logo'
import useStyles from './styles'
import icons from '../../../constants/icons'
import Button from '../../../components/Button'
import { Platform } from 'react-native'
import 'firebase/compat/functions'
import { FontAwesomeIcon } from '../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { getTextColor } from '../../../hooks/useColors'
import { Themes } from '../../../types/dashbar/theme'
import ColoredText from '../../../components/CustomText/ColoredText'
import LoadingScreen from '../../../components/LoadingScreen'
import { useLocation, useNavigate, useParams } from '../../../components/Router/Router'

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Signup = () => {
  const query = useQuery()
  const navigate = useNavigate()
  const styles = useStyles()
  const { t } = useTranslation()
  const { invitationCode } = useParams<{ invitationCode: string }>()
  const via = Platform.OS === 'web' ? query.get('via') : null
  const campaignSource = Platform.OS === 'web' ? query.get('campaign_source') : null
  const campaignName = Platform.OS === 'web' ? query.get('campaign_name') : null
  const couponParam: string | null = Platform.OS === 'web' ? query.get('coupon_code') : null

  const [invitationEmail, setInvitationEmail] = useState<string | null>(null)
  const { isLoaded, currentUser } = useContext(SessionContext)
  const setInvitationToActive = functions().httpsCallable('calls-company-setInvitationToActive')

  const [signupState, setSignupState] = useState<{
    error?: AuthError
    loading: boolean
    invitationError?: boolean
    invitationLoading?: boolean
  }>({
    error: undefined,
    loading: false,
    invitationError: false,
    invitationLoading: false,
  })

  console.debug(`LOGIN: Inviation-Code: ${invitationCode}, Via: ${via}`)
  console.debug(`Login with Campaign: ${campaignName} via ${campaignSource}`)

  useEffect(() => {
    console.log(couponParam)
  }, [couponParam])

  useEffect(() => {
    if (invitationCode) {
      setSignupState({ loading: false, invitationLoading: true })
      getApp()
        .firestore()
        .collection(`invitations`)
        .doc(invitationCode)
        .get()
        .then((result) => {
          if (result.exists) {
            setInvitationEmail(result.data()?.email)
            if (result.data()?.status === 0 || result.data()?.useCounter >= result.data()?.maxUses) {
              setSignupState({ loading: false, invitationError: true, invitationLoading: false })
            } else {
              setSignupState({ loading: false, invitationError: false, invitationLoading: false })
            }
          } else {
            setSignupState({ loading: false, invitationError: true, invitationLoading: false })
          }
        })
        .catch(() => {
          setSignupState({ loading: false, invitationError: true, invitationLoading: false })
        })
    }
  }, [invitationCode])

  useEffect(() => {
    if (isLoaded && currentUser) {
      if (couponParam && couponParam !== null) {
        currentUser.getIdTokenResult().then((result) => {
          getApp()
            .firestore()
            .collection(`company`)
            .doc(`${result?.claims?.company ?? `-`}`)
            .set(
              { deposited_coupon: { code: couponParam, depositedAt: new Date(), depositedBy: currentUser.uid } },
              { merge: true }
            )
            .finally(() => {
              navigate('/')
            })
        })
      } else {
        navigate('/')
      }
    }
  }, [navigate, isLoaded, currentUser])

  const handleSignup = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      setSignupState({ loading: true })
      if (invitationEmail !== null && invitationEmail !== email) {
        setSignupState({ loading: false, error: new AuthError('wrongEmail') })
        return
      }
      if (invitationCode) {
        await setInvitationToActive({ invitationCode: invitationCode, email: email })
      }
      if (via && via !== null) {
        await getApp().firestore().collection(`firstpromoter_leads`).add({ email: email.toLowerCase(), via: via })
      }
      getApp()
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (user) => {
          //Timeout required because it takes a few seconds until claims are set in onCreate-Function
          try {
            if (campaignName && campaignName !== null) {
              await getApp()
                .firestore()
                .collection(`campaigns/${campaignName}/signups`)
                .add({
                  signUpDate: new Date(),
                  campaignName: campaignName,
                  campaignSource: campaignSource,
                  userEmail: user.user?.email || 'n/a',
                  userId: user.user?.uid || 'n/a',
                })
            }
          } catch {
            //Catch error
          }
          await user.user?.sendEmailVerification()
          await new Promise((r) => setTimeout(r, 1000))
          await firebase.auth().currentUser?.getIdTokenResult(true)
          await new Promise((r) => setTimeout(r, 2000))
          await firebase.auth().currentUser?.getIdTokenResult(true)
          await new Promise((r) => setTimeout(r, 3000))
          await firebase.auth().currentUser?.getIdTokenResult(true)
        })
        .catch(() => {
          setSignupState({ loading: false })
          //console.debug('(Signup) Error:' + code)
        })
        .finally(async () => {
          setSignupState({ loading: false })
          await firebase.auth().currentUser?.getIdTokenResult(true)
        })
    },
    [setSignupState]
  )

  const handleProviderSignup = useCallback(
    async (provider: AuthProviderT) => {
      console.debug(`#1`)
      if (invitationCode) {
        //Provider SignUp disbaled for invitations
        return
      }
      console.debug(`#2`)
      setSignupState({ loading: true })
      getApp()
        .auth()
        .signInWithPopup(provider)

        .then(async (user) => {
          //Timeout required because it takes a few seconds until claims are set in onCreate-Function
          try {
            if (campaignName && campaignName !== null) {
              await getApp()
                .firestore()
                .collection(`campaigns/${campaignName}/signups`)
                .add({
                  signUpDate: new Date(),
                  campaignName: campaignName,
                  campaignSource: campaignSource,
                  userEmail: user.user?.email || 'n/a',
                  userId: user.user?.uid || 'n/a',
                })
            }
          } catch {
            //Catch error
          }
          console.debug(`Signed up for user ${user.user?.email}`)

          await user.user?.sendEmailVerification()
          await new Promise((r) => setTimeout(r, 1000))
          await firebase.auth().currentUser?.getIdTokenResult(true)
          await new Promise((r) => setTimeout(r, 2000))
          await firebase.auth().currentUser?.getIdTokenResult(true)
          await new Promise((r) => setTimeout(r, 3000))
          await firebase.auth().currentUser?.getIdTokenResult(true)
        })
        .catch(({ code }: { code: string }) => setSignupState({ loading: false, error: new AuthError(code) }))
        .finally(async () => {
          setSignupState({ loading: false })
          await firebase.auth().currentUser?.getIdTokenResult(true)
        })
    },
    [getApp, setSignupState]
  )
  const alert =
    signupState.error && !signupState.loading
      ? {
          content: signupState.error.message,
          color: 'error' as 'error' | 'success',
        }
      : undefined
  if (signupState.invitationLoading) {
    return <LoadingScreen />
  } else {
    if (signupState.loading) {
      return <LoadingScreen />
    }
    if (signupState.invitationError) {
      return (
        <>
          <View style={styles.container}>
            <View style={styles.wrapper}>
              <Logo />

              <View style={[styles.hairLine]} />
              <View style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ margin: 5 }}>
                  <FontAwesomeIcon icon={[icons.faIconStyle, 'exclamation-circle']} size={'lg'} />
                </View>
                <View style={[styles.container, {}]}>
                  <ColoredText style={{ textAlign: 'center', fontSize: 25 }}>{t('error.invitation.title')}</ColoredText>
                  <ColoredText style={{ textAlign: 'center' }}>{t('error.invitation.text')}</ColoredText>
                </View>
              </View>
              <View style={[styles.hairLine]} />
              <View style={[styles.container]}>
                <Button to={'/'} style={styles.homeButton}>
                  <FontAwesomeIcon color={getTextColor(Themes.Dark)} icon={[icons.faIconStyle, 'home']} />
                </Button>
              </View>
            </View>
          </View>
        </>
      )
    } else {
      return (
        <>
          <SignupView
            {...{
              alert,
              loading: signupState.loading,
              handleSignup,
              handleProviderSignup,
              providerSignupEnabled: invitationCode === undefined && via === null,
            }}
          />
        </>
      )
    }
  }
}

export default Signup
