import { IDProp } from '../types/firestore'
import getApp from '../firebase'
import { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'

const useDocument = <T>(collection: string, id?: string) => {
  const [data, setData] = useState<Record<string, any & IDProp> | null>(null)

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(getApp().firestore(), collection, id ?? `-`), (_doc) => {
      setData({ ..._doc.data(), id: _doc.id })
    })
    return () => unsubscribe()
  }, [collection, id])

  return data as (T & IDProp) | null
}

export default useDocument
