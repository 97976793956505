import { Platform, StyleSheet } from 'react-native'
import { usePrimaryBackground, useSecondaryBackground } from '../../../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    menu: {
      width: '28%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      borderRightColor: '#000',
      borderRightWidth: 0.5,
      borderStyle: 'solid',
    },
    content: {
      width: '70%',
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      alignContent: 'flex-end',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
    wrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: 20,
      marginVertical: 20,
      borderRadius: 25,
      justifyContent: Platform.select({ default: 'flex-start', web: undefined }),
      backgroundColor: useSecondaryBackground(),
      //marginTop: 10,
    },
    container: {
      flex: 1,
      width: '100%',
      alignItems: 'stretch',
      justifyContent: Platform.select({ default: 'flex-start' }),
      marginBottom: 5,
    },
    button: {
      borderRadius: 16,
      marginVertical: 2,
      paddingVertical: 5,
      paddingHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 50,
      backgroundColor: useSecondaryBackground(),
    },
    buttonText: {
      width: '75%',
    },
    menuButtonWrapper: {
      width: '90%',
      paddingHorizontal: 10,
      marginRight: 10,
      paddingVertical: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
      borderRadius: 20,
      backgroundColor: useSecondaryBackground(),
    },
    menuButtonTextWrapper: {
      width: '90%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    menuButtonText: {
      marginLeft: 5,
      fontSize: 16,
      fontFamily: 'Comfortaa_400Regular',
    },
    menuButtonActive: {
      backgroundColor: usePrimaryBackground(),
    },
  })
}

export default useStyles
