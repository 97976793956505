import React, { useContext, useEffect, useState } from 'react'
import { FlatList, Image, SafeAreaView, TouchableOpacity, View } from 'react-native'
// import { ScrollView } from 'react-native-gesture-handler'
// import FlatList from 'react-native-draggable-flatlist'
import useDashboard from '../../../../hooks/dashboard/useDashboard'
import useStyles from './styles'
import useUpdateWidgets from '../../../../hooks/widget/useUpdateWidgets'
import widgetDefinitions from '../../../../widgets'
import { get } from 'lodash'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import useRemoveWidget from '../../../../hooks/widget/useRemoveWidget'
import useRemoveDashboard from '../../../../hooks/dashboard/useRemoveDashboard'
import colors from '../../../../constants/colors'
import { SessionContext } from '../../../../context/SessionContext'
import DeleteWidgetButtonWithModal from './DeleteWidgetButtonWithModal'
import DeleteDashboardButtonWithModal from './DeleteDashboardButtonWithModal'
import NewUserTutorial from '../../../dashboard/components/NewCostumerTutorial/NewUserTutorial'
import icons from '../../../../constants/icons'
import SetDashboardAsMainButtonWithModal from './SetDashboardAsMainButtonWithModal'
import useSetDocument from '../../../../hooks/useSetDocument'
import PageWrapper from '../../../../components/PageWrapper'
import { useTranslation } from '../../../../hooks/useTranslation'
import { useNavigate, useMatch } from '../../../../components/Router/Router'
import { FontAwesomeIcon } from '../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../../components/CustomText/ColoredText'
import { useHighlightColor, useInvertedTextColor } from '../../../../hooks/useColors'
import LoadingScreen from '../../../../components/LoadingScreen'
import BoldText from '../../../../components/CustomText/BoldText'
import { IUser } from '../../../../types/user'
import { mappedVersion } from '../../../../utils/versioning'
import { SemVer } from 'semver'
import { IWidget } from '../../../../types/dashboard'

//Enables Fallback Buttons to move widgets up and down via buttons if DraggableFlatlist is not working
const ACTIVATE_SCROLLVIEW_FALLBACK = true

const ManageDashboard = () => {
  // const defaultFavIcon = require('../../../../assets/imgs/dblogo.png')
  const styles = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dashboardId = useMatch(`settings/dashboard/:dashboardId`)?.params.dashboardId || '-'

  const dashboard = useDashboard(dashboardId)
  // if (!dashboard) {
  //   navigate('..')
  // }
  const { shops, company, currentUser } = useContext(SessionContext)
  // const scrollView = useRef<ScrollView>(null)
  const updateWidgets = useUpdateWidgets(dashboardId)
  const removeWidget = useRemoveWidget(dashboardId)

  const widgets = dashboard?.widgets
  const [data, setData] = useState<Array<IWidget>>(widgets)

  const [movingWiget, setMovingWidget] = useState<boolean>(false)
  const [outerScrollEnabled /*, setOuterScrollEnabled*/] = useState(true)
  const highlightColor = useHighlightColor()
  const invertedTextColor = useInvertedTextColor()

  const removeDashboard = useRemoveDashboard()
  const manageDashboard =
    (company && company.authorizations && (company?.authorizations['dashboard.edit'] as boolean)) ?? false
  const updateUser = useSetDocument<IUser>('user')

  useEffect(() => {
    if (widgets) {
      setData(widgets)
    }
  }, [widgets])

  if (dashboard === null || shops === null) {
    return <LoadingScreen />
  }

  if (Object.keys(shops || {}).length === 0 || !shops || shops === null) {
    return <NewUserTutorial />
  }

  //Fallback-Function if DraggableFlatlist is not working. Moves Item at given id one Step up (one position earlier in widget array).
  const moveUp = (id: number) => {
    if (!ACTIVATE_SCROLLVIEW_FALLBACK) return
    if (movingWiget) return
    if (id === 0) return
    if (data === undefined) return
    setMovingWidget(true)
    const tempData = data?.slice()
    const widgetToMove = tempData?.splice(id, 1)[0]
    tempData?.splice(id - 1, 0, widgetToMove)
    setData(tempData)
    updateWidgets(tempData).then(() => {
      setMovingWidget(false)
    })
  }

  //Fallback-Function if DraggableFlatlist is not working. Moves Item at given id one Step down (one position later in widget array).
  const moveDown = (id: number) => {
    if (!ACTIVATE_SCROLLVIEW_FALLBACK) return
    if (movingWiget) return
    if (data === undefined) return
    if (id === data.length - 1) return
    setMovingWidget(true)
    const tempData = data?.slice()
    const widgetToMove = tempData?.splice(id, 1)[0]
    tempData?.splice(id + 1, 0, widgetToMove)
    setData(tempData)
    updateWidgets(tempData).then(() => {
      setMovingWidget(false)
    })
  }

  const getWidgetTitle = (widgetIsVersioned: boolean, type: string, version: SemVer): string => {
    const widgetDef = get(
      widgetDefinitions,
      widgetIsVersioned == true
        ? `${type.substring(0, type.length - 3)}_v${mappedVersion(version)}`
        : `${type}_v${mappedVersion(version)}`
    ) ?? { title: 'N/A' }
    return widgetDef.title
  }
  console.log(data?.length)
  //TODO: IMPORTANT: The scroll behaviour doesnt work as expected as long as there is a ScrollView in AppLayout (nestedScrollviews) --> Remove outer ScrollView
  // https://snack.expo.dev/@computerjazz/rndfl-nested-scrollview-example
  return (
    <SafeAreaView
      style={{
        flex: 1,
      }}
    >
      <PageWrapper isScrollable={true} scrollEnabled={outerScrollEnabled}>
        <FlatList
          data={data ?? []}
          // simultaneousHandlers={scrollView}
          style={styles.wrapper}
          contentContainerStyle={styles.container}
          // activationDistance={50}
          keyExtractor={({ id }) => id}
          //getItemLayout={(_, index) => ({ length: 55, offset: 55 * index, index })}
          //initialScrollIndex={(widgets?.length ?? 2) - 2}
          renderItem={({
            item: {
              type,
              settings: { shop },
            },
            item,
            index,
            // drag,
            // isActive,
          }) => {
            const isVersionedRegex = /.*_v[0-9]$/g
            const widgetIsVersioned = isVersionedRegex.test(type)
            const pluginVersion = shops[shop]?.platform?.pluginVersion || '1.0.0'
            const version = new SemVer(pluginVersion)
            return (
              <View style={[styles.widget /*isActive && styles.selectedWidget*/]}>
                <View
                  style={{
                    height: 25,
                    width: 25,
                    backgroundColor: colors.lightBg1,
                    borderRadius: 12.5,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {['misc.advertisement_v1'].includes(type) ? (
                    <FontAwesomeIcon icon={[icons.faIconStyle, 'dashbar' as IconName]} color={colors.color3} />
                  ) : (
                    <Image
                      style={styles.widgetIcon}
                      // height={25}
                      // width={25}
                      resizeMode={'cover'}
                      source={{
                        uri: shops ? shops[shop]?.icon : 'n/a',
                      }}
                    />
                  )}
                </View>
                <ColoredText style={[styles.widgetText /*isActive && styles.selectedWidgetText*/]}>
                  {/* {get(
                    widgetDefinitions,
                    widgetIsVersioned == true
                      ? `${type.substring(0, type.length - 3)}_v${mappedVersion(version)}`
                      : `${type}_v${mappedVersion(version)}`
                  ) ?? { title: 'N/A' }.title} */}
                  {getWidgetTitle(widgetIsVersioned, type, version)}
                </ColoredText>
                {/* {!isActive ? (
                  <>
                    <TouchableOpacity
                      onLongPress={Platform.select({
                        native: () => {
                          Vibration.vibrate(20)
                          drag()
                        },
                        web: undefined,
                      })}
                      onPressIn={Platform.select({
                        web: () => {
                          drag()
                        },
                        native: undefined,
                      })}
                      style={styles.widgetButton}
                    >
                      <FontAwesomeIcon icon={[icons.faIconStyle, 'arrows-v']} />
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        if (manageDashboard) {
                          history.push(`${dashboardId}/edit-widget/${id}`)
                        } else {
                          history.push('/upselling')
                        }
                      }}
                      style={styles.widgetButton}
                    >
                      <FontAwesomeIcon icon={[icons.faIconStyle, 'pen']} />
                    </TouchableOpacity>
                  </>
                ) : null} */}
                {ACTIVATE_SCROLLVIEW_FALLBACK && (
                  <View style={[styles.fallbackWrapper]}>
                    <TouchableOpacity
                      style={[styles.fallbackButton, movingWiget || index === 0 ? styles.fallbackButtonDisabled : null]}
                      disabled={movingWiget || index === 0}
                      onPress={() => {
                        moveUp(index)
                      }}
                    >
                      <FontAwesomeIcon icon={[icons.faIconStyle, 'up']} color={invertedTextColor} />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.fallbackButton,
                        movingWiget || index === (data?.length || 1) - 1 ? styles.fallbackButtonDisabled : null,
                      ]}
                      disabled={movingWiget || index === (data?.length || 1) - 1}
                      onPress={() => {
                        moveDown(index)
                      }}
                    >
                      <FontAwesomeIcon icon={[icons.faIconStyle, 'down']} color={invertedTextColor} />
                    </TouchableOpacity>
                  </View>
                )}
                <DeleteWidgetButtonWithModal
                  removeWidget={removeWidget}
                  updateData={setData}
                  mayManageDashboard={manageDashboard}
                  widget={item}
                  widgets={widgets || []}
                />
              </View>
            )
          }}
          scrollEnabled={false}
          // onDragEnd={({ data }) => {
          //   setOuterScrollEnabled(true)
          //   setData(data)
          //   updateWidgets(data)
          // }}
          // onDragBegin={() => {
          //   setOuterScrollEnabled(false)
          // }}
          ListFooterComponentStyle={{ marginBottom: 20 }}
          ListFooterComponent={() => (
            <>
              <TouchableOpacity
                onPress={() => {
                  navigate(`/settings/dashboard/${dashboardId}/create-widget`)
                }}
                style={styles.manageDashboardButton}
              >
                <FontAwesomeIcon icon={[icons.faIconStyle, 'plus']} color={highlightColor} />
                <BoldText style={[styles.widgetText, { color: highlightColor }]}>{t('dashboard.addWidget')}</BoldText>
              </TouchableOpacity>
              <SetDashboardAsMainButtonWithModal
                mayManageDashboard={manageDashboard}
                setDashboardAsMain={async () => updateUser({ mainDashboard: dashboardId }, currentUser?.uid)}
                dashboardTitle={dashboard?.title || 'n/a'}
              />
              <DeleteDashboardButtonWithModal
                mayManageDashboard={manageDashboard}
                removeDashboard={async () => removeDashboard(dashboardId)}
              />
            </>
          )}
        />
      </PageWrapper>
    </SafeAreaView>
  )
}

export default ManageDashboard
