import * as yup from 'yup'
import BillingAddressValidator from './BillingAddressValidator'

const CompanyValidator = yup.object().shape({
  billingAddress: BillingAddressValidator,
  ustId: yup.string().notRequired(),
  name: yup.string().required(),
  supportEmail: yup.string().email(),
})

export default CompanyValidator
