import React, { useContext } from 'react'
import ShopMenuItems from './MenuItems'
import { SessionContext } from '../../../../context/SessionContext'
import NewUserTutorial from '../../../dashboard/components/NewCostumerTutorial/NewUserTutorial'
import PageWrapper from '../../../../components/PageWrapper'
import { useTranslation } from '../../../../hooks/useTranslation'
import LoadingScreen from '../../../../components/LoadingScreen'
import { IShop } from '../../../../types/dashboard'

const Shop = () => {
  const { t } = useTranslation()
  const { shops } = useContext(SessionContext)

  if (shops === null) {
    return <LoadingScreen text={`${t('loading.shop')}`} />
  }

  if (Object.keys(shops || {}).length === 0) {
    return <NewUserTutorial />
  }

  const items = [
    ...Object.entries(shops)
      .filter(([, entry]) => entry != null)
      .map(([key, { icon }]) => ({
        domain: key,
        path: `/shops/${key}`,
        icon,
      })),
  ] as Array<IShop & { path: string }>

  return (
    <PageWrapper isScrollable={true}>
      <ShopMenuItems shops={items} />
    </PageWrapper>
  )
}
export default Shop
