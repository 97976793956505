import React, { useCallback, useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import useIntervals from '../../../../../hooks/widget/useIntervals'
import { IWidgetProps } from '../../../../../types/props/Widget'
import IntervalSelection from '../../../../components/IntervalSelection'
import Widget from '../../../../components/WidgetNoAPI'
import { useUpdateWidgetContext } from '../../../../../types/Widget'
import { clone } from 'lodash'
import { filterIntervals, getApiInterval, getSearchConsoleAnalyticsURI } from './components/helper'
import colors from '../../../../../constants/colors'
import moment from 'moment'
import InvalidInterval from './components/InvalidInterval'
import ShopSelection from './components/ShopSelection'
import DeviceGraph from './components/DeviceGraph'
import { SessionContext } from '../../../../../context/SessionContext'
import getApp, { functions } from '../../../../../firebase'
import { useTranslation } from 'react-i18next'
import GSCSkeleton from './Skeleton'

const Devices = ({ widget, ...props }: IWidgetProps) => {
  const [displayedData, setDisplayedData] = useState<Array<{ title: string; y: number; color: string }>>([
    { title: 'computer', y: 0, color: colors.color1 },
    { title: 'mobile', y: 0, color: colors.color2 },
    { title: 'tablet', y: 0, color: colors.color3 },
  ])
  const [access_token, setAccessToken] = useState(undefined)
  const [dataLoading, setDataLoading] = useState(true)

  const [selectedShop, setSelectedShop] = useState<string | null>(widget?.settings?.googleSearchShop || null)
  const [hasIntervalError, setHasIntervalError] = useState(false)
  const [hasAPIError, setHasAPIError] = useState(false)
  const [shopError, setShopError] = useState(false)
  const [error, setError] = useState<{ text: string; link: string | null }>({
    text: '',
    link: null,
  })
  const intervals = useIntervals()

  const { company } = useContext(SessionContext)
  const accessTokenQuery = getApp()
    .firestore()
    .collection('company')
    .doc(company?.id)
    .collection('extension_config')
    .doc('google_search_console')

  const handleRefreshGoogleToken = functions().httpsCallable('widgets-googleSearchConsole-handleRefreshGoogleToken')
  const [tokenRefreshed, setTokenRefreshed] = useState(false)
  async function updateRefresGoogleToken() {
    const newToken = await handleRefreshGoogleToken({ companyId: company?.id })
    setAccessToken(newToken.data.access_token)
    setTokenRefreshed(true)
  }
  const updateWidget = useUpdateWidgetContext()
  const { t } = useTranslation()
  const selectGoogleSearchShop = (shop: string) => {
    setSelectedShop(shop)
    const newWidget = {
      ...widget,
      settings: {
        ...widget.settings,
        googleSearchShop: shop,
      },
    }
    updateWidget(newWidget)
  }
  const onIntervalChange = useCallback(
    (newInterval: string) => {
      const newWidget = {
        ...widget,
        settings: {
          ...widget.settings,
          interval: newInterval,
        },
      }
      updateWidget(newWidget)
    },
    [widget, updateWidget]
  )
  function updateShop(newShop: string) {
    setSelectedShop(newShop)
    selectGoogleSearchShop(newShop)
  }
  filterIntervals(intervals)

  //fetch Token
  useEffect(() => {
    accessTokenQuery.get().then((querySnapshot) => {
      const oauth = querySnapshot.data()?.oauth
      const lastUpdated = new Date(oauth?.token.updated.seconds * 1000)
      const updatedWithinLastHour = moment(lastUpdated).isAfter(moment().subtract(1, 'hours'))

      if (updatedWithinLastHour && oauth?.token.access_token) {
        setAccessToken(oauth?.token.access_token)
      } else {
        updateRefresGoogleToken()
      }
    })
  }, [tokenRefreshed])

  useEffect(() => {
    if (access_token === undefined) return
    if (selectedShop === null) {
      setShopError(true)
      return
    }
    if (!intervals.find((x) => x.value === widget.settings.interval)?.active) {
      setHasIntervalError(true)
      return
    }

    const apiInterval: { startDate: string; endDate: string } = getApiInterval(
      widget.settings.interval,
      new Date().toISOString().split('T')[0],
      false
    )
    //Fetching the top 5 search terms
    fetch(getSearchConsoleAnalyticsURI(selectedShop), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${access_token}`,
        'User-Agent':
          'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_8_2) AppleWebKit/537.17 (KHTML, like Gecko) Chrome/24.0.1309.0 Safari/537.17',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // TODO: make Timeframe dynamic
        startDate: apiInterval.startDate,
        endDate: apiInterval.endDate,
        dimensions: ['device'],
        type: 'web',
        rowLimit: 5,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw Error(`${response.status}`)
        }
      })
      .then((data) => {
        if (data.rows === undefined) return
        const newData = clone(displayedData)
        newData[0].y = data.rows[0].clicks
        newData[1].y = data.rows[1].clicks
        newData[2].y = data.rows[2].clicks
        setDisplayedData(newData)
        setDataLoading(false)
      })
      .catch((error) => {
        switch (error.message) {
          case '403':
            setShopError(true)
            break
          case '400':
            setHasIntervalError(true)
            break
          default:
            setHasAPIError(true)
            break
        }
        setError({ text: t('shop.widget.googleSearchConsole.error.text'), link: '../extensions/google_search_console' })
      })
  }, [widget.settings.interval, selectedShop, access_token, tokenRefreshed])
  return (
    <Widget
      title={t(`shop.widget.googleSearchConsole.title.Devices`)}
      subtitle={widget.settings?.googleSearchShop}
      widget={widget}
      loading={dataLoading && !hasIntervalError && !shopError}
      loadingSkeleton={<GSCSkeleton />}
      isError={hasAPIError}
      error={error}
      customOptions={
        <IntervalSelection
          intervals={intervals}
          key="interval-selection"
          settings={widget && widget.settings}
          onIntervalChange={onIntervalChange}
        />
      }
      {...props}
    >
      {([size]) => (
        <View
          style={{
            width: size.width,
            height: size.height,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {!hasIntervalError && !shopError ? (
            <>
              <DeviceGraph size={{ width: size.width, height: size.height * 0.9 }} displayedData={displayedData} />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flexBasis: 16,
                  height: '10%',
                  width: size.width,
                }}
              >
                {displayedData.map((obj, index) => (
                  <View key={index} style={{ flexDirection: 'row' }}>
                    <View
                      style={{ backgroundColor: obj.color, height: 12, width: 12, borderRadius: 2, marginRight: 5 }}
                    />
                    <ColoredText key={index} style={{ fontSize: 12 }}>
                      {t(`shop.widget.googleSearchConsole.${obj.title}`)}
                    </ColoredText>
                  </View>
                ))}
              </View>
            </>
          ) : null}
          {hasIntervalError ? <InvalidInterval /> : null}
          {shopError ? (
            <ShopSelection
              access_token={access_token}
              setSelectedShop={updateShop}
              setShopError={setShopError}
              setError={setError}
              selectedShop={selectedShop}
            />
          ) : null}
        </View>
      )}
    </Widget>
  )
}

export default Devices
