import { useCallback, useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { Languages } from '../../types/language'
import getApp from '../../firebase'

const useStoreLanguage = () => {
  const { currentUser } = useContext(SessionContext)

  return useCallback(async (language: Languages) => {
    await getApp()
      .firestore()
      .doc(`user/${currentUser?.uid}`)
      .set({ preferences: { language: language } }, { merge: true })
  }, [])
}

export default useStoreLanguage
