import { useCallback, useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { IDashboard } from '../../types/dashboard'
import getApp from '../../firebase'

const useAddDashboard = () => {
  const { user } = useContext(SessionContext)
  return useCallback(
    async ({ icon = 'database', title }: Partial<IDashboard>) => {
      const dashboardRef = await getApp()
        .firestore()
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .add({ icon, title, widgets: [], creator: user?.id })

      return (await dashboardRef.get()).id
    },
    [user]
  )
}

export default useAddDashboard
