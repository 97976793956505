import { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { IDProp } from '../../types/firestore'
// import useCollection from '../useCollection'
// import useCollectionGroup from '../useCollectionGroup'
import { NotType } from '../../types/notification'
import { collection, collectionGroup, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import getApp from '../../firebase'

const useNotifications = () => {
  const { user } = useContext(SessionContext)

  const [shopNotifications, setShopNotifications] = useState<Record<string, NotType & IDProp> | null>({})
  const [userNotifications, setUserNotifications] = useState<Record<string, NotType & IDProp> | null>({})

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collectionGroup(getApp().firestore(), `notification`),
        where(`company`, `==`, user?.company),
        orderBy(`createdAt`, `desc`),
        limit(30)
      ),
      (_docs) => {
        let collectionMap = {}
        _docs.forEach((_doc) => {
          collectionMap = { ...collectionMap, [_doc.id]: { ..._doc.data(), id: _doc.id } }
        })
        setShopNotifications(collectionMap)
      }
    )
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(getApp().firestore(), `user/${user?.id}/notification`), orderBy(`createdAt`, `desc`), limit(30)),
      (_docs) => {
        let collectionMap = {}
        _docs.forEach((_doc) => {
          collectionMap = { ...collectionMap, [_doc.id]: { ..._doc.data(), id: _doc.id } }
        })
        setUserNotifications(collectionMap)
      }
    )
    return () => unsubscribe()
  }, [])

  const notificationList: Array<[string, NotType, string, 'user' | 'company' | 'shop']> = []
  // const shopNotifications: Record<string, NotType & IDProp> =
  //   useCollectionGroup<NotType>(`notification`, ['company', '==', user?.company], ['createdAt', 'desc']) || {}

  // const userNotifications: Record<string, NotType & IDProp> =
  //   useCollection<NotType>(`user/${user?.id}/notification`) || {}

  Object.entries(shopNotifications || {}).map((notification) => {
    notificationList.push([notification[1].id, notification[1], notification[1].shop || '', 'shop'])
  })
  Object.entries(userNotifications || {}).map((notification) => {
    notificationList.push([notification[0], notification[1], '', 'user'])
  })
  notificationList
    .sort((a, b) => {
      if (!b[1].receivedAt?.seconds || !a[1].receivedAt?.seconds) return 0
      return b[1].receivedAt.seconds - a[1].receivedAt.seconds
    })

    .filter((_v, index) => {
      return index < 20
    })
    .map((e) => {
      console.log(e)
    })
  return notificationList
}

export default useNotifications
