import { useCallback } from 'react'
import useAuth from '../useAuth'
import getApp from '../../firebase'

const useRemoveFavorite = () => {
  const auth = useAuth()

  return useCallback(
    async (...ids: Array<string>) => {
      for (const id of ids) {
        await getApp().firestore().collection(`user/${auth.uid}/favorites`).doc(id).delete()
      }
    },
    [auth]
  )
}

export default useRemoveFavorite
