import { useCallback } from 'react'
import { Favorite } from '../../types/Favorites'
import useAuth from '../useAuth'
import getApp from '../../firebase'

const useAddFavorite = () => {
  const auth = useAuth()

  return useCallback(
    async (favorite: Favorite) => {
      await getApp().firestore().collection(`user/${auth.uid}/favorites`).add(favorite)
    },
    [auth]
  )
}

export default useAddFavorite
