import React, { useContext, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { SessionContext } from '../../../../../../context/SessionContext'
import colors from '../../../../../../constants/colors'
import {
  dashboardsForBundle,
  getStatusTextForBookingProvider,
  getTextForInterval,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'
import useStyles from './styles'
import Button from '../../../../../../components/Button'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import ModalPopUp from '../../../../../../components/Modal'
import getApp, { functions } from '../../../../../../firebase'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Business from '../../../../../../assets/imgs/subscription/Business'
import Free from '../../../../../../assets/imgs/subscription/Free'
import AttentionNotice from '../../../../../../components/AttentionNotice'
import { Toast } from 'native-base'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { IBundle, Interval } from '../../../../../../types/booking'
import LoadingScreen from '../../../../../../components/LoadingScreen'

const CurrentBundle = () => {
  const styles = useStyles()
  const { company, userIsAdmin, user } = useContext(SessionContext)
  const cancelBooking = functions().httpsCallable('calls-company-booking-cancel')
  const bundle = company?.bundle as IBundle
  const { t } = useTranslation()
  const modalDeleteRef = useRef<any>()
  // const currentBooking = useCurrentBundleBooking()
  // const isMigration: boolean = currentBooking?.isMigration || false
  // const isTrial: boolean = currentBooking?.isTrial || false
  // const nextBooking = useNextBundleBooking()
  // const canBeCanceled = useCancelationPossible()
  // const currentInterval = currentBooking?.interval || Interval.year

  const [isActive, setIsActive] = useState<boolean>(true)
  const [bookingProvider, setBookingProvider] = useState<'abocloud' | 'apple' | null>(null)
  const [interval, setInterval] = useState<string>('year')
  const [isTrial, setIsTrial] = useState<boolean>(false)
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [bookingId, setBookingId] = useState<string | null>(null)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)
  const canBeCanceled = bookingProvider === 'abocloud' && isActive === true

  useEffect(() => {
    if (bundle !== IBundle.free && isCanceling === false) {
      getApp()
        .firestore()
        .collection(`company/${company?.id}/booking`)
        .get()
        .then((bookings) => {
          const bookingData = bookings.docs[0].data()
          setBookingId(bookings.docs[0].id)
          if (bookingData?.status === 1) {
            setIsActive(true)
          } else {
            setIsActive(false)
          }
          if (bookingData?.purchaseType === undefined) {
            setBookingProvider('abocloud')
          } else {
            setBookingProvider(bookingData.purchaseType)
          }
          if (bookingData?.isTrial === true) {
            setIsTrial(true)
          }
          if (bookingData.endDate) {
            setEndDate(bookingData.endDate.toDate())
          }
          if (bookingData.interval) {
            setInterval(bookingData.interval)
          }
        })
    }
  }, [isCanceling])

  const cancel = () => {
    setIsCanceling(true)
    cancelBooking({ companyId: user?.company, bookingId: bookingId })
      .then(() => {})
      .finally(() => {
        if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
        setIsCanceling(false)
      })
  }

  return (
    <ComponentWrapper title={t('subscription.your_subscription')}>
      <View
        style={[
          styles.wrapper,
          bundle === IBundle.pro || bundle === IBundle.enterprise
            ? styles.borderPro
            : bundle === IBundle.business
              ? styles.borderBusiness
              : styles.borderFree,
        ]}
      >
        <View
          style={[
            styles.titleWrapper,
            bundle === IBundle.free
              ? styles.backgroundFree
              : bundle === IBundle.pro || bundle === IBundle.enterprise
                ? styles.backgroundPro
                : styles.backgroundBusiness,
          ]}
        >
          <View style={styles.titleContentWrapper}>
            <ColoredText style={styles.title}>{`dash.bar ${bundle || IBundle.free}`}</ColoredText>
            <ColoredText style={styles.subtitle}>{`dash.bar ${bundle || IBundle.free} (${getTextForInterval(
              interval as Interval,
              isTrial
            )})`}</ColoredText>
            <ColoredText style={styles.subtitle}>
              {getStatusTextForBookingProvider(isActive, bookingProvider, bundle === IBundle.free, endDate)}
            </ColoredText>
            <AttentionNotice />
          </View>
          <View>
            {bundle === IBundle.pro || bundle === IBundle.enterprise ? (
              <Pro />
            ) : bundle === IBundle.business ? (
              <Business />
            ) : (
              <Free />
            )}
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <View style={styles.bundleOptionsWrapper}>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{userForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{shopsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{dashboardsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{notificationsForBundle(bundle)}</ColoredText>
            </View>
          </View>

          {/* <View style={styles.amountWrapper}>
            <TouchableOpacity
              style={[styles.intervalCard, interval === Interval.month ? styles.selected : null]}
              onPress={() => {
                setIntervalState({ interval: Interval.month })
                if ((currentInterval !== Interval.month || isMigration || isTrial) && userIsAdmin) {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }
              }}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.amountInterval}>
                  <>{t('subscription.interval.text_month')}</>
                </ColoredText>
                <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.month?.value}${currencyToSymbol(
                  bundleDoc?.price?.month?.currency
                )}`}</ColoredText>
                {interval === Interval.month ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.intervalCard, interval === Interval.year ? styles.selected : null]}
              onPress={() => {
                setIntervalState({ interval: Interval.year })
                if ((currentInterval !== Interval.year || isMigration || isTrial) && userIsAdmin) {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(true)
                }
              }}
            >
              <View style={styles.intervalWrapper}>
                <ColoredText style={styles.amountInterval}>
                  <>{t('subscription.interval.text_year')}</>
                </ColoredText>
                <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.year?.value}${currencyToSymbol(
                  bundleDoc?.price?.year?.currency
                )}`}</ColoredText>
                {interval === Interval.year ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
              </View>
            </TouchableOpacity>
          
          </View> */}
          {bookingProvider !== 'apple' && canBeCanceled ? (
            <View style={styles.deleteWrapper}>
              {userIsAdmin ? (
                <>
                  <Button
                    onPress={() => {
                      canBeCanceled && modalDeleteRef && modalDeleteRef.current
                        ? modalDeleteRef.current.setVisible(true)
                        : Toast.show({
                            title: <>{t('subscription.cancel_subscription_notPossible')}</>,
                          })
                    }}
                    // disabled={!canBeCanceled}
                    style={styles.button}
                    textStyle={{}}
                  >
                    <>{t('subscription.cancel_subscription')}</>
                  </Button>
                  {/* <ColoredText style={styles.deleteText}>{getCancelText(currentBooking || undefined)}</ColoredText> */}
                </>
              ) : (
                <ColoredText>
                  <>{t('subscription.only_admins')}</>
                </ColoredText>
              )}
            </View>
          ) : (
            bookingProvider === 'apple' && (
              <ColoredText style={styles.deleteText}>
                <> {t('subscription.delete_messages.apple_info')}</>
              </ColoredText>
            )
          )}
        </View>
      </View>
      {/* <BookingModal ref={modalRef} bundle={bundle} interval={interval as Interval} /> */}
      <ModalPopUp title={`${t('subscription.cancel_subscription_title')}`} ref={modalDeleteRef}>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <View style={styles.deleteModalWrapper}>
            <ColoredText>
              <>{t('subscription.cancel_subscription_text')}</>
            </ColoredText>
            {isCanceling === true ? (
              <LoadingScreen text="Kündige" />
            ) : (
              <>
                <Button
                  onPress={() => {
                    if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
                  }}
                  style={styles.cancelButton}
                >
                  <>{t('subscription.cancel')}</>
                </Button>
                <Button onPress={() => cancel()} style={styles.deleteButton} textStyle={{}}>
                  <>{t('subscription.cancel_subscription')}</>
                </Button>
              </>
            )}
          </View>
        </View>
      </ModalPopUp>
    </ComponentWrapper>
  )
}

export default CurrentBundle
