import { useCallback, useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { Themes } from '../../types/dashbar/theme'
import getApp from '../../firebase'

const useStoreTheme = () => {
  const { currentUser } = useContext(SessionContext)

  return useCallback(async (theme: Themes) => {
    await getApp()
      .firestore()
      .doc(`user/${currentUser?.uid}`)
      .set({ preferences: { theme: theme } }, { merge: true })
  }, [])
}

export default useStoreTheme
