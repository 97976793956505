import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { SessionContext } from '../../../../../context/SessionContext'
import AreaChart from '../../../../../components/VictoryCharts/Area'
import Widget from '../../../../components/WidgetNoAPI'
import HistorySkeleton from './Skeletons/HistorySkeleton'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { UpsellingContext } from '../../../../../context/UpsellingContext'
import getApp from '../../../../../firebase'

const BasicHistoryPerformance = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const { open } = useContext(UpsellingContext)
  const { company } = useContext(SessionContext)

  // const historyData = useCollection<any>(
  //   `company/${company?.id}/shop/${widget.settings.shop}/extension_pagespeed`,
  //   undefined,
  //   ['created_at', 'asc'],
  //   10
  // )

  const [historyData, setHistoryData] = useState<Array<Record<string, any>> | null>(null)
  useEffect(() => {
    if (company && company.id && widget?.settings?.shop) {
      getApp()
        .firestore()
        .collection(`company/${company?.id}/shop/${widget.settings.shop}/extension_pagespeed`)
        .orderBy(`created_at`, `asc`)
        .limit(10)
        .get()
        .then((docs) => {
          if (docs.empty) {
            setHistoryData([])
          } else {
            const historyDataArray: Array<Record<string, any>> = []
            docs.forEach((doc) => {
              historyDataArray.push({ ...doc.data(), id: doc.id })
            })
            setHistoryData(historyDataArray)
          }
        })
    }
  }, [company, widget])

  const histPerformance: Array<number> = []
  if (historyData !== null) {
    historyData?.forEach((element: any) => {
      histPerformance.push(element?.desktop_data?.performance * 100)
    })
  }
  return (
    <Widget
      title={'Basic ' + t('shop.widget.pagespeed.history.title')}
      titleIcon={'desktop'}
      widget={widget}
      isBasic
      loading={historyData === null}
      loadingSkeleton={<HistorySkeleton />}
      onClickWidget={() => open()}
      {...props}
    >
      {([size]) => {
        return (
          <View style={{ height: size.height, flexDirection: 'column' }}>
            <AreaChart data={histPerformance} size={size} />
          </View>
        )
      }}
    </Widget>
  )
}

export default BasicHistoryPerformance
