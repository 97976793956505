import numbro from 'numbro'
import React from 'react'
import Svg from 'react-native-svg'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryScatter } from 'victory'
import colors from '../../../constants/colors'
import { useHighlightColor, useTextColor } from '../../../hooks/useColors'
import { Themes } from '../../../types/dashbar/theme'
import { useTheme } from '../../../utils/types/theme'
import { useFontFamily } from '../../../hooks/useFontFamily'

interface LineChartProps {
  data: Array<number>
  size: {
    width: number
    height: number
  }
  showAxis?: boolean
  displayValues?: boolean
}

const AreaChart = ({ data, showAxis = false, size, displayValues = true }: LineChartProps) => {
  const textColor = useTextColor()
  const lineColor = useHighlightColor()
  const theme = useTheme()
  const fillColor = theme === Themes.Dark ? colors.color4 : colors.color2
  const { regular } = useFontFamily()

  const preparedData =
    data.map((v) => {
      return {
        x: `${numbro(v).format({
          mantissa: 1,
          spaceSeparated: true,
          average: true,
        })}`,
        y: v,
      }
    }) ?? []
  let preparedCategories = data.map((v) => {
    return `${numbro(v).format({
      mantissa: 1,
      spaceSeparated: true,
      average: true,
    })}`
  })

  //prevent not a number Error
  if (preparedCategories.length === 0) preparedCategories = ['']

  const screenWidth = size.width
  const screenHeight = size.height

  return (
    <Svg style={{ width: '100%', height: '100%' }} viewBox={`0,0,${screenWidth},${screenHeight}`}>
      <VictoryChart
        domainPadding={{ y: 10, x: 15 }}
        standalone={false}
        padding={{ top: 0, bottom: 25, right: 0, left: 0 }}
        width={screenWidth}
        height={screenHeight}
      >
        <VictoryArea
          width={screenWidth}
          height={screenHeight}
          standalone={false}
          scale={'linear'}
          data={preparedData}
          categories={{ x: preparedCategories }}
          interpolation="monotoneX"
          style={{
            data: {
              fill: fillColor,
              fillOpacity: theme === Themes.Dark ? 0.5 : 0.2,
              stroke: lineColor,
              strokeWidth: 3,
            },
          }}
        />
        <VictoryScatter
          width={screenWidth}
          height={screenHeight}
          standalone={false}
          style={{ data: { fill: lineColor } }}
          size={4}
          data={preparedData}
        />

        <VictoryAxis
          standalone={false}
          width={screenWidth}
          height={screenHeight}
          tickValues={preparedData.map((data) => {
            return displayValues ? data.x : ''
          })}
          padding={{ top: 0 }}
          style={{
            ...(!showAxis && { axis: { stroke: 'transparent' } }),
            tickLabels: { fill: textColor, fontFamily: regular, fontSize: 12 },
          }}
        />
      </VictoryChart>
    </Svg>
  )
}

export default AreaChart
