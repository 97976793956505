import { StyleSheet } from 'react-native'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      padding: 15,
    },
    title: {
      fontSize: 18,
      fontFamily: 'Comfortaa_400Regular',
    },
  })
}

export default useStyles
