import React from 'react'
import { ImageProps } from 'react-native'
import LazyLoadingImage from '../LazyLoadingImage'

const Avatar = ({
  photoURL,
  defaultImage = true,
  ...props
}: Partial<ImageProps> & { photoURL: string; defaultImage?: boolean }) => {
  const avatar = require('../../assets/imgs/avatar.jpg')

  return <LazyLoadingImage defaultImage={defaultImage ? avatar : undefined} imageUrl={photoURL} {...props} />
}

export default Avatar
