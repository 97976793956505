import React, { ReactElement } from 'react'
import i18n from '../../locales/i18n'
import DashboardHeader from '../dashboard/components/DashboardHeader'
import Main from './pages/Main'
import ManageDashboards from './pages/ManageDashboards'
import ManageDashboard from './pages/ManageDashboard'
import { Platform } from 'react-native'
import CreateWidget from './pages/CreateWidget'
import EditWidget from './pages/EditWidget'
import Subscription from './pages/Subscription'
import Details from './pages/Subscription/Components/Details'
import Payments from './pages/Subscription/Components/Payments'
import DetailsCurrentBundle from './pages/Subscription/Components/DetailsCurrentBundle/index.native'
import AccountOverview from './pages/AccountOverview'
import ContingentWrapper from '../../components/ContingentWrapper'
import Account from './pages/New/Account'
import CompanyData from './pages/New/CompanyData'
import Employees from './pages/New/Employees'
import Employee from './pages/New/Employee'
import Invitations from './pages/New/Invitations'
import Settings from './pages/New/Settings/index.native'
import Invoices from './pages/New/Invoices/index.native'
import Platforms from './pages/New/Platforms'
import PlatformSettings from './pages/New/PlatformSettings'
import PlatformSettingsSelect from './pages/New/PlatformSettingsSelect'
import PaymentMethod from './pages/New/PaymentMethod/index.native'
import Feedback from './pages/New/Feedback'
import Bug from './pages/New/Bug'
import CheckoutSummary from './pages/Subscription/Checkout/Summary'
import ConnectionSettings from './pages/Settings/components/ConnectionSettings'

const Routes = () => [
  {
    title: i18n.t('nav.settings'),
    name: '/settings',
    route: {
      exact: true,
      path: '/settings',
      children: <Main />,
    },
  },
  {
    title: i18n.t('nav.company_data'),
    name: '/settings/company/data',
    route: {
      exact: true,
      path: '/settings/company/data',
      children: <CompanyData />,
    },
  },
  {
    title: i18n.t('nav.employees'),
    name: '/settings/company/employees/:employee',
    route: {
      exact: true,
      path: '/settings/company/employees/:employee',
      children: <Employee />,
    },
  },
  {
    title: i18n.t('nav.employees'),
    name: '/settings/company/employees',
    route: {
      exact: true,
      path: '/settings/company/employees',
      children: <Employees />,
    },
  },
  {
    title: i18n.t('nav.invitations'),
    name: '/settings/company/invitations',
    route: {
      exact: true,
      path: '/settings/company/invitations',
      children: <Invitations />,
    },
  },
  {
    title: i18n.t('nav.invoices'),
    name: '/settings/company/invoices',
    route: {
      exact: true,
      path: '/settings/company/invoices',
      children: <Invoices />,
    },
  },
  {
    title: i18n.t('nav.payment_method'),
    name: '/settings/company/paymentMethod',
    route: {
      exact: true,
      path: '/settings/company/paymentMethod',
      children: <PaymentMethod />,
    },
  },
  {
    title: i18n.t('nav.platform_overview'),
    name: '/settings/platforms/overview',
    route: {
      exact: true,
      path: '/settings/platforms/overview',
      children: <Platforms />,
    },
  },
  {
    title: i18n.t('nav.platform_overview'),
    name: '/settings/platforms/config',
    route: {
      exact: true,
      path: '/settings/platforms/config',
      children: <ConnectionSettings />,
    },
  },
  {
    title: i18n.t('nav.platform_settings'),
    name: '/settings/platforms/settings',
    route: {
      exact: true,
      path: '/settings/platforms/settings',
      children: <PlatformSettingsSelect />,
    },
  },
  {
    title: i18n.t('nav.platform_settings'),
    name: '/settings/platforms/settings',
    route: {
      exact: true,
      path: '/settings/platforms/settings/:platformid',
      children: <PlatformSettings />,
    },
  },
  {
    title: i18n.t('nav.settings'),
    name: '/settings/user/settings',
    route: {
      exact: true,
      path: '/settings/user/settings',
      children: <Settings />,
    },
  },
  {
    title: i18n.t('nav.feedback'),
    name: '/settings/system/feedback',
    route: {
      exact: true,
      path: '/settings/system/feedback',
      children: <Feedback />,
    },
  },
  {
    title: i18n.t('nav.bug'),
    name: '/settings/system/bug',
    route: {
      exact: true,
      path: '/settings/system/bug',
      children: <Bug />,
    },
  },
  {
    title: i18n.t('nav.checkout'),
    name: '/settings/subscription/checkout/:bundle/:interval',
    route: {
      exact: true,
      path: '/settings/subscription/checkout/:bundle/:interval',
      // children: Platform.OS === 'ios' && !ALLOW_PAYMENTS_ON_IOS ? <SubscriptionWebOnly /> : <Subscription />,
      //children: <Subscription />, //Temporarly allow bookings on ios for campaign -> Remove later
      children: <CheckoutSummary />, //Subscription is no divided into default and ios component
    },
  },
  {
    title: i18n.t('nav.subscription'),
    name: '/settings/subscription',
    route: {
      exact: true,
      path: '/settings/subscription',
      // children: Platform.OS === 'ios' && !ALLOW_PAYMENTS_ON_IOS ? <SubscriptionWebOnly /> : <Subscription />,
      //children: <Subscription />, //Temporarly allow bookings on ios for campaign -> Remove later
      children: <Subscription />, //Subscription is no divided into default and ios component
    },
  },
  {
    title: i18n.t('nav.payments'),
    name: '/settings/subscription/payments',
    route: {
      exact: true,
      path: '/settings/subscription/payments',
      // children: Platform.OS === 'ios' && !ALLOW_PAYMENTS_ON_IOS ? <SubscriptionWebOnly /> : <Payments />,
      children: <Payments />,
    },
  },
  // {
  //   title: i18n.t('nav.update_mandate'),
  //   name: '/settings/settings',
  //   route: {
  //     exact: true,
  //     path: '/settings/settings',
  //     children: <SettingsOld />,
  //   },
  // },
  {
    title: i18n.t('nav.subscription'),
    name: '/settings/subscription/current',
    route: {
      exact: true,
      path: '/settings/subscription/current',
      // children: Platform.OS === 'ios' && !ALLOW_PAYMENTS_ON_IOS ? <SubscriptionWebOnly /> : <DetailsCurrentBundle />,
      children: <DetailsCurrentBundle />, //Temporarly allow bookings on ios for campaign -> Remove later
    },
  },
  {
    title: i18n.t('nav.subscription'),
    name: '/settings/subscription/:bundle',
    route: {
      exact: true,
      path: '/settings/subscription/:bundle',
      // children: Platform.OS === 'ios' && !ALLOW_PAYMENTS_ON_IOS ? <SubscriptionWebOnly /> : <Details />,
      children: <Details />, //Temporarly allow bookings on ios for campaign -> Remove later
    },
  },
  {
    title: i18n.t('nav.account_overview'),
    name: '/settings/accountOverview',
    route: {
      exact: true,
      path: '/settings/accountOverview',
      children: <AccountOverview />,
    },
  },
  {
    title: i18n.t('nav.account'),
    name: '/settings/account',
    route: {
      exact: true,
      path: '/settings/account',
      children: <Account />,
    },
  },
  {
    title: i18n.t('nav.account_overview'),
    name: '/settings/accountOverview/:invitationCode',
    route: {
      exact: true,
      path: '/settings/accountOverview/:invitationCode',
      children: <AccountOverview />,
    },
  },
  {
    title: i18n.t('nav.dashboards'),
    name: '/settings/dashboard',
    route: {
      exact: true,
      path: '/settings/dashboard',
      children: (
        <ContingentWrapper>
          <ManageDashboards />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: Platform.select<ReactElement | string>({ web: i18n.t('nav.dashboard'), default: <DashboardHeader /> }),
    name: '/settings/dashboard/:dashboardId',
    route: {
      exact: true,
      path: '/settings/dashboard/:dashboardId',
      children: (
        <ContingentWrapper>
          <ManageDashboard />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: i18n.t('nav.create-widget'),
    name: '/settings/dashboard/:dashboardId/create-widget',
    route: {
      exact: true,
      path: '/settings/dashboard/:dashboardId/create-widget',
      children: (
        <ContingentWrapper>
          <CreateWidget />
        </ContingentWrapper>
      ),
    },
  },
  {
    title: i18n.t('nav.edit-widget'), // Platform.select<ReactElement>({web: i18n.t('nav.edit-widget'), default: i18n.t('nav.edit-widget') }),
    name: '/settings/dashboard/:dashboardId/edit-widget',
    route: {
      exact: true,
      path: '/settings/dashboard/:dashboardId/edit-widget/:widgetId',
      children: (
        <ContingentWrapper>
          <EditWidget />
        </ContingentWrapper>
      ),
    },
  },
]

export default Routes
