import { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { IDashboard } from '../../types/dashboard'
import { IDProp } from '../../types/firestore'
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore'
import getApp from '../../firebase'

type DashboardMap = Record<string, IDashboard>

const useDashboards = (): DashboardMap | null => {
  const { user, currentUser, company } = useContext(SessionContext)

  //console.debug('(useDashboards)', `company/${user?.company}/dashboard`)

  const [dashboards, setDashboards] = useState<Array<IDashboard & IDProp>>([])

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(getApp().firestore(), `company/${user?.company}/dashboard`),
        where('creator', '==', currentUser?.uid ?? `-`),
        limit((company?.authorizations && (company.authorizations['dashboard.dashboards'] as number)) ?? 1)
      ),
      (_docs) => {
        const collectionArray: Array<Record<string, any>> = []
        _docs.forEach((_doc) => {
          collectionArray.push({ ..._doc.data(), id: _doc.id })
        })
        setDashboards(collectionArray as Array<IDashboard & IDProp>)
      }
    )
    return () => unsubscribe()
  }, [company?.authorizations, currentUser?.uid])

  console.debug('(useDashboards)', Object.keys(dashboards ?? {}).length)

  return dashboards
    ? dashboards
        .filter((value) => {
          return !!value // Filters out empty values created when deleting dashboards
        })
        .sort((valueA, valueB) => {
          if (user?.mainDashboard) {
            //If user has mainDashboard set mainDashboard as first item
            //console.debug(`Main Dashboard ${user?.mainDashboard}`)
            if (user.mainDashboard === valueA.id) {
              return -1
            } else if (user.mainDashboard === valueB.id) {
              return 0
            }
          }
          return valueA.title.toLowerCase().localeCompare(valueB.title.toLowerCase())
        })
        .reduce((dbs, value) => ({ ...dbs, [value.id]: value }), {}) // Create object from array
    : ({} as DashboardMap)
}

export default useDashboards
