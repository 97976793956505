import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import useStyles from './styles'
import { Image, Platform, View } from 'react-native'
import icons from '../../../../../constants/icons'
import Widget from '../../../../components/WidgetNoAPI'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import OrdersSkeleton from './OrdersSkeleton'
import getApp, { functions } from '../../../../../firebase'
import * as types from '../../../../../../functions/src/types/orders'
import ListView from '../../../../../components/ListView'
import { useNavigate } from '../../../../../components/Router/Router'
import moment from 'moment'
import { SessionContext } from '../../../../../context/SessionContext'
import { Order } from '../../../../../../functions/src/types/orders'

const methodImage = (
  <Image
    source={
      Platform.OS === 'web'
        ? require('../../../../../assets/imgs/widgets/wawi/widget_icon.png')
        : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/wawi/widget_icon.png'))
    }
    style={{ width: 15, height: 10 }}
  />
)

const Orders = ({ widget, dashboardId, shops }: IWidgetProps) => {
  const styles = useStyles()
  const { t } = useTranslation()
  const [error, setError] = useState<{ text: string; link: string | null } | null>(null)
  const [orders, setOrders] = useState<Array<Order> | null>(null)
  const [needsConfig, setNeedsConfig] = useState<boolean>(false) //TODO
  const getOrders = functions().httpsCallable('widgets-wawi-queryApi')
  const navigate = useNavigate()
  const pageSize = 1000
  const { company } = useContext(SessionContext)

  //check if account is connected to wawi api
  useEffect(() => {
    getApp()
      .firestore()
      .collection(`company/${company?.id}/extension_config`)
      .doc('jtl_wawi')
      .get()
      .then((doc) => {
        if (!doc.exists || !doc.data()) {
          setNeedsConfig(true)
          return
        }
        if (!doc.data()?.apikey || !doc.data()?.apiUrl || !doc.data()?.appId || !doc.data()?.appVersion) {
          setNeedsConfig(true)
          return
        }
        setNeedsConfig(false)
      })
    setNeedsConfig(false)
  }, [company])

  //get orders
  useEffect(() => {
    getOrders({ endpoint: 'salesOrders?pageSize=' + pageSize })
      .then((resp) => {
        setError(null)
        if (resp.data.totalItems !== null && resp.data !== undefined) {
          const data = types.toOrders(resp.data)
          //api returns oldest orders first so last page must be queried and items reversed
          if (data.totalItems > pageSize) {
            const total_pages = Math.ceil(data.totalItems / pageSize)
            getOrders({ endpoint: `salesOrders?pageSize=${pageSize}&pageNumber=${total_pages}` }).then((resp2) => {
              if (resp2.data !== null) {
                const response = types.toOrders(resp2.data)
                setOrders(response.items.reverse())
              }
            })
          } else {
            setOrders(data.items.reverse())
          }
        } else {
          setError({ text: t(`shop.widget.wawi_orders.apiFetchError`), link: null })
        }
      })
      .catch((err) => {
        setOrders(null)
        setError({ text: t(`shop.widget.wawi_orders.apiFetchError`), link: null })
        console.error(err)
      })
  }, [])

  return (
    <Widget
      title={t('shop.widget.wawi_orders.title')}
      widget={widget}
      dashboardId={dashboardId}
      shops={shops}
      loadingSkeleton={<OrdersSkeleton />}
      loading={orders === null && error === null}
      isError={error !== null}
      needsConfig={needsConfig}
      error={error}
      onClickWidget={() => {
        navigate(`/analytics/wawi/orders`)
      }}
      image={{
        source:
          Platform.OS === 'web'
            ? require('../../../../../assets/imgs/widgets/wawi/logo.png')
            : Image.resolveAssetSource(require('../../../../../assets/imgs/widgets/wawi/logo.png')).uri,
        alt: '',
      }}
    >
      {([size]) => {
        // if (orders === null && error === null) return <LoadingScreen />
        if (error !== null || orders === null) {
          return (
            <View style={styles.noData}>
              <FontAwesomeIcon
                color={'white'}
                icon={[icons.faIconStyle, 'info-circle']}
                size={Platform.select({ default: undefined, web: 'sm' })}
              />
              <ColoredText>
                <>{t('shop.widget.wawi_orders.apiFetchError')}</>
              </ColoredText>
            </View>
          )
        }
        return (
          <>
            <ListView
              size={size}
              styles={{
                thumbnailWrapper: styles.iconWrapper,
                actionTitleText: styles.orderValue,
                actionSubtitle: styles.actionSubtitle,
              }}
              data={
                orders !== null
                  ? orders.map(({ number, salesOrderDate, salesOrderPaymentDetails, comment, id }) => ({
                      id: number,
                      icon: methodImage,
                      mainTitle: ` ${t('shop.widget.wawi_orders.order')}:  ${number}`,
                      mainSubtitle: comment,
                      actionTitle: `${salesOrderPaymentDetails.currencyIso} ${salesOrderPaymentDetails.totalGrossAmount}`,
                      actionSubtitle: moment(salesOrderDate).format('L LT'),
                      warpperStyle: styles.colorPending,
                      onPress: (event) => {
                        event.preventDefault()
                        navigate(`/analytics/wawi/orders/${id}`)
                      },
                    }))
                  : null
              }
            />
          </>
        )
      }}
    </Widget>
  )
}

export default Orders
