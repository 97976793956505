import { StyleSheet } from 'react-native'
import colors from '../../../../../../../constants/colors'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: colors.red_200,
      borderRadius: 15,
      marginVertical: 15,
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignContent: 'center',
      alignItems: 'center',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: colors.red_800,
    },
    iconWrapper: {
      width: '15%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      alignItems: 'center',
    },
    buttonWrapper: {
      width: '15%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignContent: 'center',
      alignItems: 'center',
    },
    textWrapper: {
      width: '60%',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    },
    textCode: {
      fontSize: 16,
      fontWeight: '700',
      color: colors.black,
    },
    textPromo: {
      fontSize: 12,
      fontWeight: '300',
      color: colors.grey_700,
    },
  })
}

export default useStyles
