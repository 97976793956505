import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../../../../../components/PageWrapper'
import { Modal, TouchableOpacity, View } from 'react-native'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import useStyles from './styles'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import { useParams } from 'react-router-dom'
import { IBundle, Interval } from '../../../../../../types/booking'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Business from '../../../../../../assets/imgs/subscription/Business'
import Free from '../../../../../../assets/imgs/subscription/Free'
import { getTextForInterval } from '../../../../../../utils/booking'
import PaymentProviderDashbar from '../PaymentProvider/dashbar'
import { functions } from '../../../../../../firebase'
import { SessionContext } from '../../../../../../context/SessionContext'
import Link from '../../../../../../components/Link'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import moment from 'moment'
import LoadingScreen from '../../../../../../components/LoadingScreen'
import BugSVG from '../../../../../../assets/imgs/settings/BugSVG'
import CheckoutError from './CheckoutError'
import InputWithIcon from '../../../../../../components/InputWithIcon'
import Button from '../../../../../../components/Button'
import CouponSuccess from './CouponSuccess'
import CouponFailure from './CouponFailure'
import { useInvertedTextColor, useTextColor } from '../../../../../../hooks/useColors'

const CheckoutSummary = () => {
  const styles = useStyles()
  const invertedTextColor = useInvertedTextColor()
  const textColor = useTextColor()
  const purchaseAbocloud = functions().httpsCallable('calls-booking-create')
  const validateCoupon = functions().httpsCallable('calls-booking-coupon-validate')
  const [processingPayment, setProcessingPayment] = useState<boolean>(false)
  const [validatingCoupon, setValidatingCoupon] = useState<boolean>(false)
  const { t } = useTranslation()
  const { company } = useContext(SessionContext)
  const [showError, setShowError] = useState<boolean>(false)
  const [errorCode, setErrorCode] = useState<string | null>(null)
  const [couponCode, setCouponCode] = useState<string | null>(null)
  const [validatedCouponCode, setValidatedCouponCode] = useState<string | null>(null)
  const [couponHasError, setCouponHasError] = useState<boolean>(false)
  const [couponResult, setCouponResult] = useState<{
    amountOld: { currency: string; value: string }
    amountNew: { currency: string; value: string }
    promoText: string
    code: string
  } | null>(null)

  const { bundle, interval } = useParams<{ bundle: IBundle; interval: string }>()

  const purchase = async (id: string) => {
    if (processingPayment === true) return
    if (validatingCoupon === true) return
    console.log(validatedCouponCode)
    setErrorCode(null)
    setProcessingPayment(true)
    console.log(`${bundle}_${interval}ly`, `${id}`)
    try {
      const result = await purchaseAbocloud({
        cid: company?.id,
        bid: `${bundle}_${interval}ly`,
        couponid: couponResult?.code ?? null,
      })
      // .then((result) => {
      if (result?.data?.success === true && result?.data?.checkoutUrl !== null) {
        window.location.href = result.data.checkoutUrl
      } else if (result?.data?.success === false) {
        setErrorCode(result?.data?.error || null)
        setShowError(true)
      }
      console.log(result)
      // })
    } catch (err) {
      setShowError(true)
      console.log(err)
    }
    setProcessingPayment(false)
  }

  useEffect(() => {
    const checkCoupon = async () => {
      if (validatingCoupon) return
      setValidatingCoupon(true)
      try {
        const couponResp = await validateCoupon({
          cid: company?.id,
          bid: `${bundle}_${interval}ly`,
          cpid: company.deposited_coupon?.code?.trim(),
        })
        setCouponResult(couponResp?.data)
        setValidatedCouponCode(couponResp?.data?.code)
        setCouponCode(couponResp?.data?.code)
      } catch (e) {
        console.log(`Didnt find coupon`, e)
      }
      setValidatingCoupon(false)
    }

    if (company.deposited_coupon !== undefined) {
      checkCoupon()
    }
  }, [company])

  const validate = async () => {
    if (validatingCoupon) return
    setValidatingCoupon(true)
    setValidatedCouponCode(null)
    setCouponHasError(false)
    setCouponResult(null)
    try {
      const couponResponse = await validateCoupon({
        cid: company?.id,
        bid: `${bundle}_${interval}ly`,
        cpid: couponCode.trim(),
      })
      setCouponResult(couponResponse.data)
      setValidatedCouponCode(couponResponse.data.code)
    } catch (e) {
      // setCouponCode(null)
      console.log(e)
      setCouponResult(null)
      setCouponHasError(true)
      setValidatedCouponCode(null)
    }
    setValidatingCoupon(false)
  }

  const resetCoupon = () => {
    setCouponResult(null)
    setCouponHasError(false)
    setCouponCode(null)
    setValidatedCouponCode(null)
  }

  return (
    <PageWrapper isScrollable>
      <ComponentWrapper title={`Dein Kauf`}>
        <View style={styles.wrapper}>
          <View
            style={[
              styles.titleWrapper,
              bundle === IBundle.free
                ? styles.backgroundFree
                : bundle === IBundle.pro || bundle === IBundle.enterprise
                  ? styles.backgroundPro
                  : styles.backgroundBusiness,
            ]}
          >
            <View>
              <ColoredText style={styles.title}>{`dash.bar ${bundle || IBundle.free}`}</ColoredText>
              <ColoredText style={styles.subTitle}>{`${getTextForInterval(interval as Interval, false)}, ${t(
                'checkout.starts'
              )} ${moment().format('DD.MM.YYYY')}`}</ColoredText>
            </View>
            <View>
              {bundle === IBundle.pro || bundle === IBundle.enterprise ? (
                <Pro />
              ) : bundle === IBundle.business ? (
                <Business />
              ) : (
                <Free />
              )}
            </View>
          </View>
          <View style={styles.legalWrapper}>
            <ColoredText style={styles.legalText}>{`${t('checkout.legal_text_1')}`}</ColoredText>
            <Link fontSize={12} url="">{`${t('checkout.conditions')}`}</Link>
            <ColoredText style={styles.legalText}>{`${t('checkout.legal_text_2')}`}</ColoredText>
            <Link fontSize={12} url="">{`${t('checkout.privacy_policy')}`}</Link>
            <ColoredText style={styles.legalText}>{`${t('checkout.legal_text_3')}`}</ColoredText>
            <ColoredText style={styles.legalText}>{`${t('checkout.legal_text_4')}`}</ColoredText>
          </View>
        </View>
      </ComponentWrapper>
      <ComponentWrapper title={`${t('checkout.coupon.code')}`}>
        {couponHasError === true ? (
          <View style={{ width: '45%' }}>
            <CouponFailure code={couponCode} resetCoupon={resetCoupon} />
          </View>
        ) : couponResult !== null && couponHasError === false ? (
          <View style={{ width: '45%' }}>
            <CouponSuccess
              amountNew={couponResult.amountNew}
              amountOld={couponResult.amountOld}
              code={couponResult.code}
              promoText={couponResult.promoText}
              resetCoupon={resetCoupon}
            />
          </View>
        ) : (
          <View style={styles.couponWrapper}>
            <View style={{ width: '35%' }}>
              <InputWithIcon
                icon={'ticket-perforated'}
                defaultColor={textColor}
                viewStyle={{ marginVertical: 10, borderColor: textColor }}
                placeholder={`${t('checkout.coupon.code')}`}
                value={couponCode ?? ''}
                onChangeText={setCouponCode}
                readOnly={validatingCoupon}
              />
            </View>
            <Button onPress={validate} textStyle={{ color: invertedTextColor }} style={styles.couponButton}>
              <>{`${t('checkout.coupon.apply')}`}</>
            </Button>
          </View>
        )}
      </ComponentWrapper>
      <ComponentWrapper title={`${t('checkout.payment_method')}`}>
        {processingPayment === true ? (
          <LoadingScreen text={`${t('checkout.processing_payment')}`} />
        ) : validatingCoupon === true ? (
          <LoadingScreen text={`${t('checkout.coupon.validating')}`} />
        ) : (
          <View style={styles.paymentMethodWrapper}>
            <PaymentProviderDashbar onPress={async () => purchase(`dashbar`)} type="paypal" />
            <PaymentProviderDashbar onPress={async () => purchase(`dashbar`)} type="creditcard" />
            <PaymentProviderDashbar onPress={async () => purchase(`dashbar`)} type="directdebit" />
          </View>
        )}
      </ComponentWrapper>
      <Modal
        animationType="fade"
        visible={showError}
        transparent={true}
        onRequestClose={() => {
          setShowError(!showError)
        }}
      >
        <View style={styles.errorModalWrapper}>
          <View style={styles.errorModal}>
            <View style={styles.errorModalTitleWrapper}>
              <BugSVG width={150} height={80} style={{ marginTop: 10, marginBottom: 15 }} viewBox="0 0 520 400" />
              <ColoredText style={styles.errorModalTitle}>Fehler bei der Buchung</ColoredText>
            </View>
            <View style={styles.errorModalTextWrapper}>
              <CheckoutError message={errorCode} />
            </View>
            <View style={styles.errorModalActionWrapper}>
              <TouchableOpacity
                onPress={() => {
                  setShowError(false)
                  setErrorCode(null)
                }}
                style={styles.errorModalButton}
              >
                <ColoredText style={styles.errorModalButtonText}>OK</ColoredText>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </PageWrapper>
  )
}

export default CheckoutSummary
