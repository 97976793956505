import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import ModalPopUp from '../../../../../../../components/Modal'
import { SessionContext } from '../../../../../../../context/SessionContext'
import getApp, { functions } from '../../../../../../../firebase'
import useStyles from './styles'
import UserView from './UserView'
import { Toast } from 'native-base'
import EmailForm from '../../../../../../../components/Form/EmailForm'
import Button from '../../../../../../../components/Button'
import useDocument from '../../../../../../../hooks/useDocument'
import { UpsellingContext } from '../../../../../../../context/UpsellingContext'
import { useTranslation } from '../../../../../../../hooks/useTranslation'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import LoadingScreen from '../../../../../../../components/LoadingScreen'
import { IUser } from '../../../../../../../types/user'

const CompanyUsers = () => {
  const { company, user } = useContext(SessionContext)
  const styles = useStyles()
  const modalRef = useRef<any>()
  const [sendingIvitation, setSendingInvitation] = useState<boolean>(false)
  const { t } = useTranslation()
  const { open } = useContext(UpsellingContext)

  const ownerId = company?.owner || '-'
  const adminList = (company?.admin?.length ? company?.admin : ['-'])?.filter((element) => {
    if (element !== '-') {
      return element !== ownerId
    }
    return true
  })
  const userList = (company?.users?.length ? company?.users : ['-'])?.filter((element) => {
    if (element !== '-') {
      return !adminList.includes(element) && element !== ownerId
    }
    return true
  })

  // const admins = useCollection<IUser>('user', [['id', 'in', adminList.length > 0 ? adminList : ['-']]])
  // const users = useCollection<IUser>('user', [['id', 'in', userList.length > 0 ? userList : ['-']]])

  const [users, setUsers] = useState<Array<IUser>>([])
  const [admins, setAmins] = useState<Array<IUser>>([])
  useEffect(() => {
    if (userList) {
      getApp()
        .firestore()
        .collection(`user`)
        .where(`id`, `in`, userList.length > 0 ? userList : ['-'])
        .get()
        .then((docs) => {
          if (docs.empty) {
            setUsers(null)
          } else {
            const userArray: Array<IUser> = []
            docs.forEach((doc) => {
              userArray.push({ ...(doc.data() as IUser) })
            })
            setUsers(userArray)
          }
        })
    }
  }, [userList])
  useEffect(() => {
    if (adminList) {
      getApp()
        .firestore()
        .collection(`user`)
        .where(`id`, `in`, adminList.length > 0 ? adminList : ['-'])
        .get()
        .then((docs) => {
          if (docs.empty) {
            setAmins(null)
          } else {
            const userArray: Array<IUser> = []
            docs.forEach((doc) => {
              userArray.push({ ...(doc.data() as IUser) })
            })
            setAmins(userArray)
          }
        })
    }
  }, [adminList])

  const owner = useDocument<IUser>('user', ownerId)
  const createInvitation = useCallback(
    (email: string) => {
      setSendingInvitation(true)
      const createInvitation = functions().httpsCallable('calls-company-createInvitation')
      createInvitation({ maxUses: 1, email: email })
        .then(() => {})
        .catch((e) => {
          Toast.show({
            description: e.message,
          })
        })
        .finally(() => {
          if (modalRef && modalRef.current) {
            modalRef.current.setVisible(false)
            setSendingInvitation(false)
          }
        })
    },
    [company?.name, t, Toast, user?.firstName, user?.lastName]
  )

  const onPressAddUser = () => {
    const canAddUsers = (company?.users?.length || 0) < +(company?.authorizations['general.users'] || 0)

    if (modalRef && modalRef.current && canAddUsers) {
      modalRef.current.setVisible(true)
    } else {
      open()
    }
  }

  return (
    <>
      <ColoredText style={{ paddingHorizontal: 30, paddingVertical: 10, fontSize: 18 }}>
        <>{t('accountOverview.company.employees')}</>
      </ColoredText>
      <View style={styles.userWrapper}>
        {owner?.id ? <UserView isOwner isAdmin user={owner} /> : null}
        {Object.entries(admins || {}).map(([k, value]) => {
          return <UserView isAdmin key={k} user={value} />
        })}
        {Object.entries(users || {}).map(([k, value]) => {
          return <UserView key={k} user={value} />
        })}

        <TouchableOpacity onPress={() => onPressAddUser()} style={styles.userViewWrapper}>
          <View style={styles.addButton}>
            <FontAwesomeIcon icon={'user-plus'} color="white" />
          </View>
          <ColoredText style={styles.name}>
            <>{t('accountOverview.company.add')}</>
          </ColoredText>
        </TouchableOpacity>

        <ModalPopUp ref={modalRef} title={`${t('accountOverview.company.invite_user')}`}>
          {sendingIvitation ? (
            <LoadingScreen />
          ) : (
            <View style={{ width: '50%', margin: 'auto' }}>
              <EmailForm
                style={{ width: '100%' }}
                onSubmit={({ email }) => createInvitation(email)}
                submitButtonText={t('company.inviteUser')}
                loading={false}
              />
              <Button
                style={styles.cancelButton}
                onPress={() => {
                  if (modalRef && modalRef.current) modalRef.current.setVisible(false)
                }}
              >
                <>{t('accountOverview.profile.cancel')}</>
              </Button>
            </View>
          )}
        </ModalPopUp>
      </View>
    </>
  )
}

export default CompanyUsers
