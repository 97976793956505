import React, { useContext, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { SessionContext } from '../../../../../../context/SessionContext'
import useStyles from './styles'
import PageWrapper from '../../../../../../components/PageWrapper'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Free from '../../../../../../assets/imgs/subscription/Free'
import {
  dashboardsForBundle,
  getStatusTextForBookingProvider,
  getTextForInterval,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'
import colors from '../../../../../../constants/colors'
import { IBundle, Interval } from '../../../../../../types/booking'
import Button from '../../../../../../components/Button'
import ModalPopUp from '../../../../../../components/Modal'
import Business from '../../../../../../assets/imgs/subscription/Business'
import AttentionNotice from '../../../../../../components/AttentionNotice'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import getApp, { functions } from '../../../../../../firebase'
import LoadingScreen from '../../../../../../components/LoadingScreen'

const DetailsCurrentBundle = () => {
  const { company, userIsAdmin, user } = useContext(SessionContext)
  const bundle = company?.bundle as IBundle
  const modalDeleteRef = useRef<any>()
  const styles = useStyles()
  const { t } = useTranslation()
  const cancelBooking = functions().httpsCallable('calls-company-booking-cancel')

  const [isActive, setIsActive] = useState<boolean>(true)
  const [bookingProvider, setBookingProvider] = useState<'abocloud' | 'apple' | null>(null)
  const [interval, setInterval] = useState<string>('year')
  const [isTrial, setIsTrial] = useState<boolean>(false)
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [bookingId, setBookingId] = useState<string | null>(null)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)
  const canBeCanceled = bookingProvider === 'abocloud' && isActive === true

  useEffect(() => {
    if (bundle !== IBundle.free && isCanceling == false) {
      getApp()
        .firestore()
        .collection(`company/${company?.id}/booking`)
        .get()
        .then((bookings) => {
          const bookingData = bookings.docs[0].data()
          setBookingId(bookings.docs[0].id)
          if (bookingData?.status === 1) {
            setIsActive(true)
          } else {
            setIsActive(false)
          }
          if (bookingData?.purchaseType === undefined) {
            setBookingProvider('abocloud')
          } else {
            setBookingProvider(bookingData.purchaseType)
          }
          if (bookingData?.isTrial === true) {
            setIsTrial(true)
          }
          if (bookingData.endDate) {
            setEndDate(bookingData.endDate.toDate())
          }
          if (bookingData.interval) {
            setInterval(bookingData.interval)
          }
        })
    }
  }, [isCanceling])

  const cancel = () => {
    setIsCanceling(true)
    cancelBooking({ companyId: user?.company, bookingId: bookingId })
      .then(() => {})
      .finally(() => {
        setIsCanceling(false)
        if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
      })
  }
  return (
    <PageWrapper isScrollable={true}>
      <View style={styles.wrapper}>
        <View style={styles.mobileCard}>
          <View
            style={[
              styles.bundleWrapper,
              bundle === IBundle.free
                ? styles.backgroundFree
                : bundle === IBundle.pro || bundle === IBundle.enterprise
                  ? styles.backgroundPro
                  : styles.backgroundBusiness,
            ]}
          >
            <ColoredText style={{ textAlign: 'center', marginBottom: 15, color: 'white' }}>{bundle}</ColoredText>
            <View>
              {bundle === IBundle.pro || bundle === IBundle.enterprise ? (
                <Pro width={90} height={40} viewBox="0 0 190 100" />
              ) : bundle === IBundle.business ? (
                <Business width={90} height={50} viewBox="20 5 190 120" />
              ) : (
                <Free width={90} height={60} viewBox="-35 0 190 140" />
              )}
            </View>
          </View>
          <View style={styles.bundleTextWrapper}>
            <ColoredText>{`dash.bar ${bundle}`}</ColoredText>
            <ColoredText>{getTextForInterval(interval as Interval, isTrial)}</ColoredText>
            <ColoredText>
              {getStatusTextForBookingProvider(isActive, bookingProvider, bundle === IBundle.free, endDate)}
            </ColoredText>
            <AttentionNotice />
          </View>
        </View>
        <View style={styles.bundleOptionsWrapper}>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{userForBundle(bundle)}</ColoredText>
          </View>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{shopsForBundle(bundle)}</ColoredText>
          </View>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{dashboardsForBundle(bundle)}</ColoredText>
          </View>
          <View style={styles.itemWrapper}>
            <FontAwesomeIcon icon={'check'} color={colors.success} />
            <ColoredText style={styles.itemText}>{notificationsForBundle(bundle)}</ColoredText>
          </View>
        </View>
        {bookingProvider !== 'apple' && canBeCanceled === true ? (
          <View style={styles.buttonWrapper}>
            {userIsAdmin ? (
              <>
                <Button
                  onPress={() => {
                    if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(true)
                  }}
                  style={styles.deleteButton}
                  disabled={!canBeCanceled}
                  textStyle={{ color: colors.error }}
                >
                  <> {t('subscription.cancel_subscription')}</>
                </Button>
                {/* <ColoredText style={styles.deleteText}>{getCancelText(currentBooking || undefined)}</ColoredText> */}
              </>
            ) : (
              <ColoredText>
                <> {t('subscription.only_admins')}</>
              </ColoredText>
            )}
          </View>
        ) : (
          bookingProvider === 'apple' && (
            <ColoredText style={styles.deleteText}>
              <> {t('subscription.delete_messages.apple_info')}</>
            </ColoredText>
          )
        )}
      </View>
      {bookingProvider === 'abocloud' && (
        <ModalPopUp title={`${t('subscription.cancel_subscription_title')}`} ref={modalDeleteRef}>
          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <View style={styles.deleteModalWrapper}>
              <ColoredText>
                <>{t('subscription.cancel_subscription_text')}</>
              </ColoredText>
              {isCanceling === true ? (
                <LoadingScreen text="Kündige" />
              ) : (
                <>
                  <Button
                    onPress={() => {
                      if (modalDeleteRef && modalDeleteRef.current) modalDeleteRef.current.setVisible(false)
                    }}
                    style={styles.cancelButton}
                  >
                    <>{t('subscription.cancel')}</>
                  </Button>
                  <Button
                    onPress={() => cancel()}
                    style={styles.deleteSubscriptionButton}
                    textStyle={{ color: colors.error }}
                  >
                    <>{t('subscription.cancel_subscription')}</>
                  </Button>
                </>
              )}
            </View>
          </View>
        </ModalPopUp>
      )}
    </PageWrapper>
  )
}

export default DetailsCurrentBundle
