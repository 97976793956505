import React, { ReactNode, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useIntervals from '../../../hooks/widget/useIntervals'
import IntervalSelection from '../../components/IntervalSelection'
import { useUpdateWidgetContext } from '../../../types/Widget'
import WidgetView, { PropsT as BaseProps } from '../../../components/Widget'
import { Modify } from '../../../types'
import useRemoveWidget from '../../../hooks/widget/useRemoveWidget'
import useDashboard from '../../../hooks/dashboard/useDashboard'
import Actions from '../../components/Actions'
import { LayoutChangeEvent, View } from 'react-native'
import DefaultDashbarIcon from '../../components/DefaultDashbarIcon.tsx'
import { usePrimaryBackground } from '../../../hooks/useColors'
import LoadingScreen from '../../../components/LoadingScreen'
import ColoredText from '../../../components/CustomText/ColoredText'
import { ApiConfigFunction, useShopApi } from '../../apis/api_shop'
import { getErrorByStatusCode } from '../../../utils/WidgetErrors'
import ErrorComponent from '../ErrorComponent'
import { IShop, IWidget, ShopMap } from '../../../types/dashboard'
import { UpsellingContext } from '../../../context/UpsellingContext'
import ConfigComponent from '../ConfigComponent/index'
const defaultFavIcon = 'https://static.dash.bar/app/img/dblogo.png'

export interface WidgetSize {
  height: number
  width: number
}

type ChildrenParams<DataTypes> = [boolean, DataTypes, Error | null, IShop | null, WidgetSize]

interface WidgetProps<DataTypes> {
  widget: IWidget
  dashboardId: string
  getApiConfig: Array<ApiConfigFunction>
  children: (args: ChildrenParams<DataTypes>) => React.ReactNode
  shops: ShopMap
  hasInterval?: boolean
  isBasic?: boolean
  showMissingData?: boolean
  loadingSkeleton?: ReactNode | null
  needsConfig?: boolean
}

const Widget = <DataTypes,>({
  widget,
  dashboardId,
  children,
  footerContent,
  getApiConfig,
  hasInterval = false,
  isBasic = false,
  showMissingData = false,
  showOptions = true,
  shops,
  loadingSkeleton,
  needsConfig,
  ...props
}: Modify<BaseProps, WidgetProps<DataTypes>>) => {
  const backgroundColor = usePrimaryBackground()
  const updateWidget = useUpdateWidgetContext()
  const removeWidget = useRemoveWidget(dashboardId)
  const dashboard = useDashboard(dashboardId)
  const { open } = useContext(UpsellingContext)
  const widgets = dashboard?.widgets ?? null
  const { t } = useTranslation()
  const [openOptions, setOpenOptions] = useState<boolean>(false)

  const [size, setSize] = useState({ height: 200, width: 100 })
  const onIntervalChange = useCallback(
    (newInterval: string) => {
      //Intervall cannot be changed for basic widgets
      if (isBasic === true) {
        open()
      } else {
        const newWidget = {
          ...widget,
          settings: {
            ...widget.settings,
            interval: newInterval,
          },
        }
        updateWidget(newWidget)
        setOpenOptions(false)
      }
    },
    [widget, updateWidget]
  )

  const intervals = useIntervals()

  const {
    id,
    settings: { shop, ...settings },
  } = widget

  const widgetShop = shops[shop]

  const { data, isLoading, isError, error, isFetching } = useShopApi<DataTypes>(
    id,
    settings,
    getApiConfig[0],
    widgetShop
  )

  const onWidgetLayout = useCallback(
    (evt: LayoutChangeEvent) => {
      const { height, width } = evt.nativeEvent.layout
      setSize({ height, width })
    },
    [isLoading]
  )

  if (needsConfig) {
    return <ConfigComponent shop={widgetShop} title={props.title} image={props.image} />
  }

  if (!widgetShop) {
    const errorInfo: {
      text: string
      link: string | null
    } = {
      text: t('error.widgetShopMissing'),
      link: null,
    }
    return (
      <ErrorComponent error={errorInfo} shop={widgetShop} title={props.title} dbCode={error?.dbCode || undefined} />
    )
  }

  if (isError) {
    let errorInfo: {
      text: string
      link: string | null
    }
    if (error?.dbError && error.dbError !== null) {
      errorInfo = {
        text: error.dbError.text || 'Error',
        link: error.dbError.link || null,
      }
    } else {
      errorInfo = getErrorByStatusCode(+(error?.status || 500))
    }

    return (
      <ErrorComponent error={errorInfo} shop={widgetShop} title={props.title} dbCode={error?.dbCode || undefined} />
    )
  }

  // if (widgets === null) {
  //   return <LoadingScreen />
  // }

  return (
    <WidgetView
      image={
        widgetShop?.icon
          ? {
              source: widgetShop?.icon ?? defaultFavIcon,
              alt: widgetShop?.domain ?? '(domain missing)',
            }
          : undefined
      }
      // icon={
      //   widgetShop?.icon ? undefined : (
      //     <FontAwesomeIcon icon={[icons.faIconStyle, 'dashbar' as IconName]} color={colors.color3} />
      //   )
      // }
      icon={
        widgetShop?.icon ? undefined : (
          // <FontAwesomeIcon icon={[icons.faIconStyle, 'dashbar' as IconName]} color={colors.color3} />
          <DefaultDashbarIcon />
        )
      }
      footerContent={
        footerContent
        //||
        // (error && error?.message) ||
        // (hasInterval && (intervals.find(({ value }) => widget.settings.interval === value) || {}).label)
      }
      headerOptionText={
        hasInterval ? (intervals.find(({ value }) => widget.settings.interval === value) || {}).label : undefined
      }
      openOptions={openOptions}
      setOpenOptions={setOpenOptions}
      options={[
        hasInterval && (
          <IntervalSelection
            intervals={intervals}
            key="interval-selection"
            settings={widget && widget.settings}
            onIntervalChange={onIntervalChange}
          />
        ),
        <Actions
          key={'actions'}
          widget={widget}
          widgets={widgets}
          onRemoveWidget={() => {
            //Basic Widgets cannot be removed
            if (isBasic === true) {
              open()
            } else {
              removeWidget(widget, widgets)
            }
          }}
        />,
      ]}
      showOptions={showOptions}
      onWidgetLayout={onWidgetLayout}
      {...props}
    >
      {showMissingData && data ? (
        <>
          {children([isLoading, data, error, widgetShop, size])}
          {isLoading || isFetching ? (
            loadingSkeleton && loadingSkeleton !== null ? (
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0.7,
                }}
              >
                {loadingSkeleton}
              </View>
            ) : (
              <LoadingScreen
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor,
                  opacity: 0.7,
                }}
              />
            )
          ) : null}
        </>
      ) : isLoading || isFetching || widgets === null ? (
        loadingSkeleton && loadingSkeleton !== null ? (
          loadingSkeleton
        ) : (
          <LoadingScreen />
        )
      ) : !isLoading && !isFetching && data ? (
        children([isLoading, data, error, widgetShop, size])
      ) : (
        <ColoredText>
          <>{t('shop.widget.no-data')}</>
        </ColoredText>
      )}
    </WidgetView>
  )
}

export default Widget
