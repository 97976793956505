import { Text, View } from 'native-base'
import React, { useContext, useEffect, useState } from 'react'
import useStyles from './styles'
import { SessionContext } from '../../../context/SessionContext'
import getApp from '../../../firebase'

const BundleComponent = () => {
  const styles = useStyles()
  const { company } = useContext(SessionContext)
  const [isTrial, setIsTrial] = useState<boolean>(false)

  useEffect(() => {
    if (company && company.id) {
      getApp()
        .firestore()
        .collection(`company/${company.id}/booking`)
        .get()
        .then((docs) => {
          if (docs.empty) {
            setIsTrial(false)
          } else {
            const bookingDoc = docs.docs[0]
            setIsTrial(bookingDoc.data()?.isTrial ?? false)
          }
        })
    }
  }, [company])

  return (
    <View style={styles.wrapper}>
      <Text allowFontScaling={false} style={styles.text}>
        {`${company?.bundle || 'free'} ${isTrial ? '(trial)' : ''}`}
      </Text>
    </View>
  )
}

export default BundleComponent
