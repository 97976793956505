import { useCallback } from 'react'
import useDashboard from '../dashboard/useDashboard'
import useUpdateWidgets from './useUpdateWidgets'
import { IWidget } from '../../types/dashboard'

const useUpdateWidget = (dashboardId: string) => {
  const updateWidgets = useUpdateWidgets(dashboardId)

  const dashboard = useDashboard(dashboardId)

  return useCallback(
    async (widget: IWidget) =>
      dashboard !== null &&
      updateWidgets(
        dashboard?.widgets
          ? dashboard?.widgets.map(({ id, ...rest }) => {
              if (id === widget.id) {
                return widget
              }

              return {
                id,
                ...rest,
              }
            })
          : []
      ),
    [dashboard, updateWidgets]
  )
}

export default useUpdateWidget
