import { Favorite } from '../../types/Favorites'
import { useContext, useEffect, useState } from 'react'
import { IDProp } from '../../types/firestore'
import { collection, onSnapshot, query } from 'firebase/firestore'
import getApp from '../../firebase'
import { SessionContext } from '../../context/SessionContext'

const useFavorites = (): Record<string, Favorite> => {
  const { currentUser } = useContext(SessionContext)

  const [favorites, setFavorites] = useState<Record<string, Favorite & IDProp> | null>(null)

  useEffect(() => {
    if (!currentUser?.uid) return undefined
    const unsubscribe = onSnapshot(
      query(collection(getApp().firestore(), `user/${currentUser?.uid}/favorites`)),
      (_docs) => {
        let collectionMap = {}
        _docs.forEach((_doc) => {
          collectionMap = { ...collectionMap, [_doc.id]: { ..._doc.data(), id: _doc.id } }
        })
        setFavorites(collectionMap)
      }
    )
    return () => unsubscribe()
  }, [currentUser?.uid])

  // useFirestoreConnect(() => [
  //   {
  //     collection: `user/${auth.uid}/favorites`,
  //     storeAs: 'user_favorites',
  //   },
  // ])

  // const favorites =
  //   useSelector(
  //     (state: { firestore: { data: { user_favorites?: Record<string, Favorite> } } }) =>
  //       state.firestore.data?.user_favorites
  //   ) || ({} as Record<string, Favorite>)
  return favorites
}

export default useFavorites
