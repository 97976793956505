import * as React from 'react'
import Svg, { SvgProps, Defs, RadialGradient, Stop, LinearGradient, Path, G, Use, Text, TSpan } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title, filter */

const DashbarLogin = (props: SvgProps) => (
  <Svg width={169} height={27} {...props}>
    <Defs>
      <RadialGradient
        cx="50%"
        cy="2.498%"
        fx="50%"
        fy="2.498%"
        r="84.551%"
        gradientTransform="matrix(0 .9796 -1 0 .525 -.465)"
        id="a"
      >
        <Stop stopColor="#FDCE21" offset="0%" />
        <Stop stopColor="#FDA500" offset="100%" />
      </RadialGradient>
      <RadialGradient
        cx="50%"
        cy="100%"
        fx="50%"
        fy="100%"
        r="222.877%"
        gradientTransform="matrix(0 -1 .21429 0 .286 1.5)"
        id="e"
      >
        <Stop stopColor="#FFF" offset="0%" />
        <Stop stopColor="#FBEFC5" offset="100%" />
      </RadialGradient>
      <LinearGradient x1="0%" y1="45.033%" x2="100%" y2="45.033%" id="c">
        <Stop stopColor="#FDC61A" stopOpacity={0} offset="0%" />
        <Stop stopColor="#FDC61A" stopOpacity={0.711} offset="75.626%" />
        <Stop stopColor="#E09200" offset="100%" />
      </LinearGradient>
      <Path
        d="M3.429 0h13.714c1.893 0 3.428 1.485 3.428 3.316v14.368c0 1.831-1.535 3.316-3.428 3.316H3.429C1.535 21 0 19.515 0 17.684V3.316C0 1.485 1.535 0 3.429 0Z"
        id="b"
      />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <G transform="translate(3 3)">
        <Use fill="url(#a)" xlinkHref="#b" />
        <Use fill="url(#c)" xlinkHref="#b" />
      </G>
      <Path
        d="M2.888 15h14.366c1.359 0 2.46 1.151 2.46 2.571 0 1.42-1.101 2.572-2.46 2.572H2.888c-1.358 0-2.46-1.151-2.46-2.572C.429 16.151 1.53 15 2.889 15Z"
        fill="#CF8A03"
        opacity={0.253}
        transform="translate(3 3)"
      />
      <Path
        d="M6.262 18.429H20.31c1.328 0 2.405 1.055 2.405 2.357 0 1.302-1.077 2.357-2.405 2.357H6.262c-1.328 0-2.405-1.055-2.405-2.357 0-1.302 1.077-2.357 2.405-2.357Z"
        fill="#FFF"
      />
      <Path
        d="M3.582 15.857H16.99c1.268 0 2.296.864 2.296 1.929s-1.028 1.928-2.296 1.928H3.582c-1.268 0-2.296-.863-2.296-1.928s1.028-1.929 2.296-1.929Z"
        fill="url(#e)"
        transform="translate(3 3)"
      />
      <Text
        fontFamily={'Comfortaa_700Bold'}
        fontSize={16}
        fillRule="nonzero"
        fontWeight="bold"
        fill="#FFF"
        transform="translate(3)"
      >
        <TSpan x={41} y={18}>
          {'jetzt einloggen'}
        </TSpan>
      </Text>
    </G>
  </Svg>
)

export default DashbarLogin
