import { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../context/SessionContext'
import { IDashboard } from '../../types/dashboard'
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore'
import getApp from '../../firebase'
import { IDProp } from '../../types/firestore'

const useDashboard = (dashboardId: string): IDashboard | undefined => {
  const { user, currentUser, company } = useContext(SessionContext)

  const [dashboards, setDashboards] = useState<Array<IDashboard & IDProp>>([])

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(getApp().firestore(), `company/${user?.company}/dashboard`),
        where('creator', '==', currentUser?.uid),
        limit((company?.authorizations && (company.authorizations['dashboard.dashboards'] as number)) ?? 1)
      ),
      (_docs) => {
        const collectionArray: Array<Record<string, any>> = []
        _docs.forEach((_doc) => {
          collectionArray.push({ ..._doc.data(), id: _doc.id })
        })
        setDashboards(collectionArray as Array<IDashboard & IDProp>)
      }
    )
    return () => unsubscribe()
  }, [company?.authorizations])

  return (
    dashboards.find((v) => {
      return v.id === dashboardId
    }) ?? null
  )
}

export default useDashboard
