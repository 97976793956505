import { TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import { FontAwesomeIcon } from '../../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import icons from '../../../../../../../constants/icons'
import colors from '../../../../../../../constants/colors'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../../../hooks/useTranslation'

const CouponSuccess = ({
  amountNew,
  promoText,
  code,
  resetCoupon,
}: {
  amountOld: { currency: string; value: string }
  amountNew: { currency: string; value: string }
  promoText: string
  code: string
  resetCoupon: () => void
}) => {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <View style={styles.wrapper}>
      <View style={styles.iconWrapper}>
        <FontAwesomeIcon icon={[icons.faIconStyle, 'check-circle']} fontSize={24} color={colors.green_800} />
      </View>
      <View style={styles.textWrapper}>
        <ColoredText style={styles.textCode}>{`${code}`}</ColoredText>
        <ColoredText
          style={styles.textPromo}
        >{`${promoText} (${`${t('checkout.coupon.new_amount', { amount: amountNew.value.replace('.', ',') })}`})`}</ColoredText>
      </View>
      <View style={styles.buttonWrapper}>
        <TouchableOpacity onPress={resetCoupon}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'xmark']} color={colors.black} />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default CouponSuccess
