// import { useSelector } from 'react-redux'
// import { IAuth } from '../types/dashbar/auth'
import getApp from '../firebase'
import { useEffect, useState } from 'react'
import { User } from 'firebase/auth'

/** @description
 * Migrated from @webstollen/shared-react-components
 */

const useAuth = () => {
  const [auth, setAuth] = useState<User | null>(null)

  useEffect(
    () =>
      getApp()
        .auth()
        .onAuthStateChanged((auth) => {
          setAuth(auth)
        }),
    []
  )

  // const auth = useSelector((state: { firebase: { auth: IAuth } }) => state.firebase.auth)

  return {
    ...auth,
    uid: auth?.uid ?? null,
    providerData: auth?.providerData ?? null,
    isEmpty: auth !== null,
    isLoaded: auth !== null,
    isAuthed: auth !== null,
  }
}

export default useAuth
