import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import AreaChart from '../../default/OrderValue/AreaChart'
import { OrderValueT } from '../../../../../types/widgetDataTypes/types'
import Widget from '../../../../components/Widget'
import OrdervalueSkeleton from './OrdervalueSkeleton'
import { ISettings } from '../../../../../types/dashboard'
import { UpsellingContext } from '../../../../../context/UpsellingContext'

const BasicOrderValue = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const { open } = useContext(UpsellingContext)

  return (
    <Widget<OrderValueT>
      title={'Basic ' + t('shop.widget.order-value.title')}
      widget={widget}
      hasInterval
      isBasic
      loadingSkeleton={<OrdervalueSkeleton />}
      getApiConfig={[
        ({ autoRefresh, refreshInterval, interval = 'D30' }: ISettings) => ({
          body: {
            request: 'Analytics',
            widget: {
              sales: {
                options: {
                  active: true,
                  details: true,
                  interval,
                  refresh: autoRefresh ? refreshInterval : undefined,
                },
              },
            },
          },
          widget: 'sales',
        }),
      ]}
      onClickWidget={() => open()}
      {...props}
    >
      {([, data, , , size]) => {
        if (!data) return null
        if (data && data?.avg_history) {
          return (
            //Werte bei Basic Widget nur gerundet
            <AreaChart
              data={[Math.round(data.avg), ...data.avg_history.map((val) => Math.round(val))].reverse()}
              interval={widget?.settings?.interval}
              size={size}
            />
          )
        } else {
          return <AreaChart data={[Math.round(data.avg)]} size={size} interval={widget?.settings?.interval} />
        }
      }}
    </Widget>
  )
}

export default BasicOrderValue
