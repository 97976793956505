import React from 'react'
import { IDProp } from '../../../types/firestore'
import useNotifications from '../../../hooks/notifications/useNotifications'
import NotificationView from './NotificationView'
import NotificationFilterDialog from './NotificationFilterDialog'
import { clone } from 'lodash'
import { Platform, ScrollView } from 'react-native'
import { Toast } from 'native-base'
import { useTranslation } from '../../../hooks/useTranslation'
import LoadingScreen from '../../../components/LoadingScreen'
import ColoredText from '../../../components/CustomText/ColoredText'
import { NotType } from '../../../types/notification'
import { IUser } from '../../../types/user'
import getApp from '../../../firebase'

type NotificationSettings = {
  shopList: Array<string>
  notificationType: Array<string>
}

const DisplayedNotificationsList = ({
  shops,
  user,
  isVisible,
  setisVisible,
}: {
  shops: Array<string>
  user: IUser & IDProp
  isVisible: boolean
  setisVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [deletedNotificationIDs, setDeletedNotificationsIDs] = React.useState<Array<string>>([])
  const notificationList: Array<[string, NotType, string, 'user' | 'company' | 'shop']> = useNotifications()
  const { t } = useTranslation()
  const [filter, setFilter] = React.useState<NotificationSettings>({
    shopList: shops,
    notificationType: ['newsFilter', 'technicalFilter', 'customerEngagement', 'reportFilter'],
  })
  const newsFilter = [
    'dashBar:accInfo',
    'dashBar:stream',
    'dashBar:report',
    'company:invitation:received',
    'user:adminNot',
  ]
  const technicalFilter = ['shop:connected', 'shop:watcher', 'shop:offline', 'shop:online']
  const reportFilter = ['report:daily', 'report:weekly', 'report:monthly', 'report:yearly']
  const alwaysAllowed = [
    'special:force',
    'special:upselling',
    'admin:true',
    'admin:false',
    'owner:true',
    'owner:false',
    ...reportFilter,
  ]
  const customerEngagementFilter = ['shop:evaluation', 'shop:order', 'shop:ordercontactRequest', 'shop:newComment']

  //TODO: are shop notifications loaded?
  const onDelete = (id: string) => {
    setDeletedNotificationsIDs([...deletedNotificationIDs, id])
    const notification = notificationList.filter((obj) => obj[0] === id)
    console.log(notification[0])

    if (notification[0][2] === '') {
      //USERNOTification:
      //Reports are in company collection this should be handle more clean in the future
      if (notification[0][1]?.data?.type === 'dashbar:report') {
        getApp()
          .firestore()
          .doc(`company/${user?.company ?? '-'}/notification/${id}`)
          .delete()
          .then(() => {
            console.debug('Notification successfully deleted!')
          })
          .catch((error) => {
            Toast.show({
              title: t('accountOverview.user.error_deleting_companyNotification'),
            })
            console.error('Deleting Notification not possible : ', error)
          })
      } else {
        getApp()
          .firestore()
          .doc(`user/${user.id ?? '-'}/notification/${id}`)
          .delete()
          .then(() => {
            console.debug('Notification successfully deleted!')
          })
          .catch((error) => {
            Toast.show({
              title: t('accountOverview.user.error_deleting_companyNotification'),
            })
            console.error('Deleting Notification not possible : ', error)
          })
      }
    } else {
      //SHOP notification
      getApp()
        .firestore()
        .doc(
          `company/${user?.company ?? '-'}/shop/${
            notificationList.find((item) => item[0] === id)[2]
          }/notification/${id}`
        )
        .delete()
        .then(() => {
          console.debug('Notification successfully deleted!')
        })
        .catch((error) => {
          Toast.show({
            title: t('accountOverview.user.error_deleting_companyNotification'),
          })
          console.error('Deleting Notification not possible : ', error)
        })
    }
  }
  if (notificationList.length === 0) return <LoadingScreen text={`${t('loading.notifications')}`} />

  async function updateFilter(value: string, updateCategory: string) {
    const newFilter = clone(filter)
    // update ShopFilter
    if (updateCategory === 'shopList') {
      const containsItem = filter.shopList.includes(value)
      if (containsItem) {
        newFilter.shopList = newFilter.shopList.filter((shop) => shop !== value)
      } else {
        newFilter.shopList = [...newFilter.shopList, value]
      }
    }
    if (updateCategory === 'notificationType') {
      const containsItem = filter.notificationType.includes(value)
      if (containsItem) {
        newFilter.notificationType = newFilter.notificationType.filter((notType) => notType !== value)
      } else {
        newFilter.notificationType = [...newFilter.notificationType, value]
      }
    }
    setFilter(newFilter)
  }

  const filteredNotification = notificationList.filter((notification) => {
    const notificationType = notification[1].data?.type || ''
    const shopIsSelected = filter.shopList.includes(notification[2])

    //notifications who are passed through by default: :
    if (notification[2] === '') return true
    if (alwaysAllowed.includes(notificationType)) return true
    if (deletedNotificationIDs.includes(notification[0])) return false
    if (!shopIsSelected) return false
    if (filter.notificationType.includes('newsFilter') && newsFilter.includes(notificationType)) return true
    if (filter.notificationType.includes('technicalFilter') && technicalFilter.includes(notificationType)) return true
    if (filter.notificationType.includes('customerEngagement') && customerEngagementFilter.includes(notificationType))
      return true
    if (filter.notificationType.includes('reportFilter') && reportFilter.includes(notificationType)) return true

    return false
  })
  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      style={{ minHeight: Platform.OS === 'web' ? 500 : 200, width: '100%', height: '100%' }}
    >
      {filteredNotification.map((n) => {
        return (
          <NotificationView key={n[0]} notification={n[1]} notificationID={n[0]} shopName={n[2]} onDelete={onDelete} />
        )
      })}

      {filteredNotification.length === 0 && (
        <ColoredText style={{ alignSelf: 'center' }}>
          <>{t('notifications.noNotifications')}</>
        </ColoredText>
      )}

      <NotificationFilterDialog
        shops={shops}
        filter={filter}
        isVisible={isVisible}
        setisVisible={setisVisible}
        updateFilter={updateFilter}
      />
    </ScrollView>
  )
}

export default DisplayedNotificationsList
