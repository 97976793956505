import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { Platform, View } from 'react-native'
import useDocument from '../../../../../hooks/useDocument'
import { SessionContext } from '../../../../../context/SessionContext'
import moment from 'moment'
import colors from '../../../../../constants/colors'
import icons from '../../../../../constants/icons'
import useStyles from './styles'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import Widget from '../../../../components/WidgetNoAPI'
import { useNavigate } from '../../../../../components/Router/Router'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import WebvitalsSkeleton from './Skeletons/WebvitalsSkeleton'

export const processMetricColorForCategory = (first_metric: string): string => {
  if (first_metric === undefined) {
    return colors.error
  }
  if (first_metric === 'FAST') {
    return colors.success
  } else {
    if (first_metric === 'AVERAGE') return colors.pagespeed_average
  }
  return colors.error
}
export const processMetricIconForCategory = (first_metric: string): IconName => {
  if (first_metric === undefined) {
    return 'times-circle'
  }
  if (first_metric === 'FAST') return 'check-circle'
  return 'times-circle'
}

export const processMetricIconForHistory = (current_metric: number, last_metric: number): IconName => {
  if (last_metric === undefined || last_metric === null) {
    return 'minus'
  }
  if (current_metric - last_metric === 0) {
    return 'minus'
  } else {
    if (current_metric - last_metric > 0) {
      return 'arrow-down'
    }
    return 'arrow-up'
  }
}
export const processIconColorForHistory = (current_metric: number, last_metric: number): string => {
  if (last_metric === undefined || last_metric === null) {
    return ''
  }
  if (current_metric - last_metric === 0) {
    return ''
  } else {
    if (current_metric - last_metric > 0) {
      return colors.error
    }
    return colors.success
  }
}

const WebVitals = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const { company } = useContext(SessionContext)
  const styles = useStyles()
  const navigate = useNavigate()

  const weekNumber = (hist: boolean): string => {
    if (hist) {
      return moment(new Date()).subtract(1, 'week').format('YYYY_W')
    } else {
      return moment(new Date()).format('YYYY_W')
    }
  }

  const noData = (
    <View style={styles.noData}>
      <FontAwesomeIcon style={{ marginBottom: 3 }} icon={[icons.faIconStyle, 'info-circle']} />
      <ColoredText>
        Im Bericht zur Nutzererfahrung in Chrome sind nicht genügend tatsächliche Geschwindigkeitsdaten für diese Seite
        vorhanden.
      </ColoredText>
    </View>
  )

  //get current Pagespeed Data
  //mobile: pagespeedData.desktop_data
  //desktop: pagespeedData.desktop_data

  //TODO überprüfen ob daten vorhanden sind
  const pagespeedData = useDocument<Record<string, any>>(
    `company/${company?.id}/shop/${widget.settings.shop}/extension_pagespeed`,
    weekNumber(false)
  )
  const hist_pagespeedData = useDocument<Record<string, any>>(
    `company/${company?.id}/shop/${widget.settings.shop}/extension_pagespeed`,
    weekNumber(true)
  )

  return (
    <Widget
      title={t('shop.widget.pagespeed.webvitals.title')}
      titleIcon={'desktop'}
      widget={widget}
      loading={pagespeedData === null}
      loadingSkeleton={<WebvitalsSkeleton />}
      onClickWidget={() => {
        // console.log(pagespeedData.desktop_data)
        //alert('TODO: implement') /*"TODO: implement"*/
        navigate(`/analytics/pagespeed/${widget.settings.shop}`) // TODO: add correct link
      }}
      {...props}
    >
      {([size]) => {
        // if (!isLoaded(pagespeedData)) return <LoadingScreen />
        return (
          <View style={[{ height: size.height }, Platform.OS === 'web' ? { marginTop: 20 } : { marginTop: 10 }]}>
            {pagespeedData?.desktop_data?.loadingExperience.error ? (
              noData
            ) : (
              <>
                <View style={styles.statusItem}>
                  <View style={{ flexDirection: 'row' }}>
                    <FontAwesomeIcon
                      color={processMetricColorForCategory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS?.category
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForCategory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS?.category
                        ),
                      ]}
                    />
                    <ColoredText style={styles.itemText}>Largest Contentful Paint (LCP) </ColoredText>
                  </View>
                  <View style={[styles.histIcon]}>
                    <ColoredText style={[styles.itemCurrentValue]}>
                      {pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS ===
                      undefined
                        ? 'n/a '
                        : Number(
                            pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS
                              ?.percentile / 1000
                          ).toFixed(2) + 's '}
                    </ColoredText>
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      color={processIconColorForHistory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS
                          ?.percentile,
                        hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS
                          ?.percentile
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForHistory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS
                            ?.percentile,
                          hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.LARGEST_CONTENTFUL_PAINT_MS
                            ?.percentile
                        ),
                      ]}
                    />
                  </View>
                </View>
                <View style={styles.statusItem}>
                  <View style={{ flexDirection: 'row' }}>
                    <FontAwesomeIcon
                      color={processMetricColorForCategory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS?.category
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForCategory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS?.category
                        ),
                      ]}
                    />
                    <ColoredText style={styles.itemText}>First Input Delay (FID): </ColoredText>
                  </View>
                  <View style={styles.histIcon}>
                    <ColoredText style={styles.itemCurrentValue}>
                      {pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS === undefined
                        ? 'n/a'
                        : pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS?.percentile +
                          'ms'}
                    </ColoredText>
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      color={processIconColorForHistory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS?.percentile,
                        hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS?.percentile
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForHistory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS?.percentile,
                          hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_INPUT_DELAY_MS?.percentile
                        ),
                      ]}
                    />
                  </View>
                </View>
                <View style={styles.statusItem}>
                  <View style={{ flexDirection: 'row' }}>
                    <FontAwesomeIcon
                      color={processMetricColorForCategory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE?.category
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForCategory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE
                            ?.category
                        ),
                      ]}
                    />
                    <ColoredText style={styles.itemText}>Cumulative Layout Shift (CLS) </ColoredText>
                  </View>
                  <View style={styles.histIcon}>
                    <ColoredText style={styles.itemCurrentValue}>
                      {pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE ===
                      undefined
                        ? 'n/a'
                        : pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE
                            ?.percentile / 100}
                    </ColoredText>
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      color={processIconColorForHistory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE
                          ?.percentile,
                        hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE
                          ?.percentile
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForHistory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE
                            ?.percentile,
                          hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.CUMULATIVE_LAYOUT_SHIFT_SCORE
                            ?.percentile
                        ),
                      ]}
                    />
                  </View>
                </View>
                <View style={styles.statusItem}>
                  <View style={{ flexDirection: 'row' }}>
                    <FontAwesomeIcon
                      color={processMetricColorForCategory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS?.category
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForCategory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS?.category
                        ),
                      ]}
                    />
                    <ColoredText style={styles.itemText}>First Contentful Paint (FCP) </ColoredText>
                  </View>
                  <View style={styles.histIcon}>
                    <ColoredText style={styles.itemCurrentValue}>
                      {pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS === undefined
                        ? 'n/a'
                        : Number(
                            pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS
                              ?.percentile / 1000
                          ).toFixed(2) + 's'}
                    </ColoredText>
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      color={processIconColorForHistory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS?.percentile,
                        hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS
                          ?.percentile
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForHistory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS
                            ?.percentile,
                          hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.FIRST_CONTENTFUL_PAINT_MS
                            ?.percentile
                        ),
                      ]}
                    />
                  </View>
                </View>
                <View style={styles.statusItem}>
                  <View style={{ flexDirection: 'row' }}>
                    <FontAwesomeIcon
                      color={processMetricColorForCategory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT
                          ?.category
                      )}
                      icon={[
                        icons.faIconStyle,
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT
                          ?.category === 'FAST'
                          ? 'check-circle'
                          : 'times-circle',
                      ]}
                    />
                    <ColoredText style={styles.itemText}>Interaction to Next Paint (INP) </ColoredText>
                  </View>
                  <View style={styles.histIcon}>
                    <ColoredText style={styles.itemCurrentValue}>
                      {pagespeedData?.desktop_data?.loadingExperience?.metrics
                        ?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT === undefined
                        ? 'n/a'
                        : pagespeedData?.desktop_data?.loadingExperience?.metrics
                            ?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT?.percentile + 'ms'}
                    </ColoredText>
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      color={processIconColorForHistory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT
                          ?.percentile,
                        hist_pagespeedData?.desktop_data?.loadingExperience?.metrics
                          ?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT?.percentile
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForHistory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics
                            ?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT?.percentile,
                          hist_pagespeedData?.desktop_data?.loadingExperience?.metrics
                            ?.EXPERIMENTAL_INTERACTION_TO_NEXT_PAINT?.percentile
                        ),
                      ]}
                    />
                  </View>
                </View>
                <View style={styles.statusItem}>
                  <View style={{ flexDirection: 'row' }}>
                    <FontAwesomeIcon
                      color={processMetricColorForCategory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE
                          ?.category
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForCategory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE
                            ?.category
                        ),
                      ]}
                    />
                    <ColoredText style={styles.itemText}>Time to First Byte (TTFB) </ColoredText>
                  </View>
                  <View style={styles.histIcon}>
                    <ColoredText style={styles.itemCurrentValue}>
                      {pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE ===
                      undefined
                        ? 'n/a'
                        : Number(
                            pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE
                              ?.percentile / 1000
                          ).toFixed(2) + 's'}
                    </ColoredText>
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      color={processIconColorForHistory(
                        pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE
                          ?.percentile,
                        hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE
                          ?.percentile
                      )}
                      icon={[
                        icons.faIconStyle,
                        processMetricIconForHistory(
                          pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE
                            ?.percentile,
                          hist_pagespeedData?.desktop_data?.loadingExperience?.metrics?.EXPERIMENTAL_TIME_TO_FIRST_BYTE
                            ?.percentile
                        ),
                      ]}
                    />
                  </View>
                </View>
              </>
            )}
          </View>
        )
      }}
    </Widget>
  )
}

export default WebVitals
