import React, { useContext } from 'react'
import { IWidgetProps } from '../../../../../types/props/Widget'
import { View } from 'react-native'
import useDocument from '../../../../../hooks/useDocument'
import { SessionContext } from '../../../../../context/SessionContext'
import moment from 'moment'
import icons from '../../../../../constants/icons'
import useStyles from './styles'
import colors from '../../../../../constants/colors'
import { ProgressCircleComponent } from './ProgressCircleComponent'
import Widget from '../../../../components/WidgetNoAPI'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { useNavigate } from '../../../../../components/Router/Router'
import { FontAwesomeIcon } from '../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../../../../../components/CustomText/ColoredText'
import PerformanceSkeleton from './Skeletons/PerformanceSkeleton'

const Performance = ({ widget, ...props }: IWidgetProps) => {
  const { t } = useTranslation()
  const { company } = useContext(SessionContext)
  const styles = useStyles()
  const navigate = useNavigate()

  const weekNumber = (): string => {
    return moment(new Date()).format('YYYY_W')
  }

  //get current Pagespeed Data
  //mobile: pagespeedData.desktop_data
  //desktop: pagespeedData.desktop_data
  const pagespeedData = useDocument<Record<string, any>>(
    `company/${company?.id}/shop/${widget.settings.shop}/extension_pagespeed`,
    weekNumber()
  )
  const noData = (
    <View style={styles.noData}>
      <FontAwesomeIcon style={{ marginBottom: 3 }} color="white" icon={[icons.faIconStyle, 'info-circle']} />
      <ColoredText>{t('shop.widget.pagespeed.performance.noData')}</ColoredText>
    </View>
  )

  return (
    <Widget
      title={t('shop.widget.pagespeed.performance.title')}
      titleIcon={'desktop'}
      loading={pagespeedData === null}
      loadingSkeleton={<PerformanceSkeleton />}
      widget={widget}
      onClickWidget={() => {
        //alert('TODO: implement') /*"TODO: implement"*/
        navigate(`/analytics/pagespeed/${widget.settings.shop}`) // TODO: add correct link
      }}
      {...props}
    >
      {([size]) => {
        return (
          <View style={{ height: size.height, width: '100%', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            {!pagespeedData?.desktop_data?.performance ? (
              noData
            ) : (
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <View style={{ flexDirection: 'column', justifyContent: 'space-evenly' }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ alignItems: 'center', alignSelf: 'center', marginRight: 8 }}
                      icon={'square'}
                      color={colors.success}
                      scale={0.8}
                    />
                    <ColoredText style={{ marginLeft: 2 }}>
                      {`90-100%\n${t('shop.widget.pagespeed.performance.fast')}`}
                    </ColoredText>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={'circle'} color={'#FDAB49'} scale={0.8} style={{ marginRight: 8 }} />
                    <ColoredText style={{ marginLeft: 2 }}>
                      {`50-89%\n${t('shop.widget.pagespeed.performance.average')}`}
                    </ColoredText>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={'triangle'} color={colors.error} scale={0.8} style={{ marginRight: 8 }} />
                    <ColoredText style={{ marginLeft: 2 }}>
                      {`0-49%\n${t('shop.widget.pagespeed.performance.slow')}`}
                    </ColoredText>
                  </View>
                </View>
                <View>
                  <ProgressCircleComponent
                    pagespeed_data={pagespeedData?.desktop_data?.performance}
                    isMobile={true}
                    size={{ height: 200, width: 160 }}
                  />
                  {/* <ColoredText style={{ height: size.height / 2, backgroundColor: 'red' }}>Hallo </ColoredText> */}
                </View>
              </View>
            )}
          </View>
        )
      }}
    </Widget>
  )
}

export default Performance
