import React, { useContext, useEffect, useState } from 'react'
import MenuButtonSwitch from '../../../components/MenuButtonSwitch/index.native'
import { useSetTheme } from '../../../../base/store/actions'
import useStoreTheme from '../../../../../hooks/preferences/useStoreTheme'
import { useTheme } from '../../../../../utils/types/theme'
import { Themes } from '../../../../../types/dashbar/theme'
import SelectList from '../../../components/SelectList'
import { useLanguage, useSetLanguage } from '../../../../../common/helpers/language'
import useStoreLanguage from '../../../../../hooks/preferences/useStoreLanguage'
import { useTranslation } from '../../../../../hooks/useTranslation'
import { Languages } from '../../../../../types/language'
import MenuTitle from '../../../components/MenuTitle/index.native'
import { SessionContext } from '../../../../../context/SessionContext'
import { intervalListBusiness, intervalListFree, intervalListPro } from './list'
import { storeSettings } from './funcs'
import PageWrapper from '../../../../../components/PageWrapper'
import useStoreNotificationSettings from '../../../../../hooks/preferences/useStoreNotificationSettings'
import getApp from '../../../../../firebase'

const Settings = () => {
  const setTheme = useSetTheme()
  const storeTheme = useStoreTheme()
  const setLanguage = useSetLanguage()
  const storeLanguage = useStoreLanguage()
  const storeNotificationSettings = useStoreNotificationSettings()
  const currentLanguage = useLanguage()
  const { company, user } = useContext(SessionContext)
  const { t } = useTranslation()
  const theme = useTheme()

  const languages = [
    {
      name: t('layout.language.deutsch'),
      id: Languages.DE,
    },
    {
      name: t('layout.language.english'),
      id: Languages.EN,
    },
  ]

  const getRefreshInterval = (): Array<{ name: string; id: string }> => {
    if (!company?.bundle || company.bundle === null) {
      return intervalListFree
    }
    if (company.bundle === 'free') {
      return intervalListFree
    } else if (company.bundle === 'business') {
      return intervalListBusiness
    } else if (company.bundle === 'pro') {
      return intervalListPro
    }
    return intervalListFree
  }

  //General
  const [darkmode, setDarkmode] = useState<boolean>(theme === Themes.Dark)
  const [currLanguage, setCurrLanguage] = useState<string | null>(currentLanguage)

  //Widgets
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false)
  const [refreshInterval, setRefreshInterval] = useState<string | null>(null)

  //Notifications
  const [ntfShopOrder, setNtfShopOrder] = useState<boolean>(false)
  const [ntfShopContact, setNtfShopContact] = useState<boolean>(false)
  const [ntfShopComment, setNtfShopComment] = useState<boolean>(false)
  const [ntfShopEvaluation, setNtfShopEvaluation] = useState<boolean>(false)
  const [ntfWatcher, setNtfWatcher] = useState<boolean>(false)
  const [ntfDashbarReport, setNtfDashbarReport] = useState<boolean>(false)
  const [ntfDashbarStream, setNtfDashbarStream] = useState<boolean>(false)

  const [settingsLoaded, setSettingsLoaded] = useState<boolean>(false)

  useEffect(() => {
    getApp()
      .firestore()
      .doc(`user/${user?.id}`)
      .get()
      .then((doc) => {
        const widgetSettings = doc.data()?.preferences?.widget || null
        const notificationSettings = doc.data()?.preferences?.notifications || null
        if (widgetSettings && widgetSettings !== null) {
          setAutoRefresh(widgetSettings.autoRefresh === true)
          setRefreshInterval(`${widgetSettings.interval}`)
        }
        if (notificationSettings && notificationSettings !== null) {
          setNtfShopOrder(notificationSettings[`shop:order`] === true)
          setNtfShopContact(notificationSettings[`shop:contact`] === true)
          setNtfShopComment(notificationSettings[`shop:comment`] === true)
          setNtfShopEvaluation(notificationSettings[`shop:evaluation`] === true)
          setNtfWatcher(notificationSettings[`watcher:online`] === true)
          setNtfDashbarReport(notificationSettings[`dashbar:report`] === true)
          setNtfDashbarStream(notificationSettings[`dashbar:stream`] === true)
        }
      })
      .finally(() => {
        setSettingsLoaded(true)
      })
  }, [])

  useEffect(() => {
    if (darkmode === true && theme !== Themes.Dark) {
      setTheme(Themes.Dark)
      storeTheme(Themes.Dark)
    }
    if (darkmode === false && theme !== Themes.Light) {
      setTheme(Themes.Light)
      storeTheme(Themes.Light)
    }
  }, [darkmode])

  useEffect(() => {
    if (currentLanguage !== (currLanguage as Languages)) {
      setLanguage(currLanguage as Languages)
      storeLanguage(currLanguage as Languages)
    }
  }, [currLanguage])

  useEffect(() => {
    storeSettings({ autoRefresh: autoRefresh, interval: refreshInterval }, company?.bundle)
  }, [refreshInterval, autoRefresh])

  useEffect(() => {
    if (settingsLoaded) {
      storeNotificationSettings({
        [`shop:contact`]: ntfShopContact === true,
        [`shop:order`]: ntfShopOrder === true,
        [`shop:comment`]: ntfShopComment === true,
        [`shop:evaluation`]: ntfShopEvaluation === true,
        [`watcher:online`]: ntfWatcher === true,
        [`watcher:offline`]: ntfWatcher === true,
        [`watcher:maintenance:on`]: ntfWatcher === true,
        [`watcher:maintenance:off`]: ntfWatcher === true,
        [`dashbar:report`]: ntfDashbarReport === true,
        [`dashbar:stream`]: ntfDashbarStream === true,
      })
    }
  }, [ntfShopOrder, ntfShopContact, ntfShopEvaluation, ntfShopComment, ntfWatcher, ntfDashbarReport, ntfDashbarStream])

  return (
    <PageWrapper scrollViewStyle={{ justifyContent: 'flex-start', marginHorizontal: -10 }} isScrollable={true}>
      <MenuTitle title={`${t('settings.mobile.title.general')}`} />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.darkmode')}`}
        enabled={darkmode}
        setEnabled={setDarkmode}
      />
      <SelectList
        title={`${t('settings.mobile.options.personal.language')}`}
        items={languages}
        selectId={setCurrLanguage}
        selectedId={currLanguage}
      />
      <MenuTitle title={`${t('settings.mobile.title.widgets')}`} />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.auto_refresh')}`}
        enabled={autoRefresh}
        setEnabled={setAutoRefresh}
      />
      <SelectList
        title={`${t('settings.mobile.options.personal.interval')}`}
        items={getRefreshInterval()}
        selectId={setRefreshInterval}
        selectedId={refreshInterval}
      />
      <MenuTitle title={`${t('settings.mobile.title.notifications')}`} />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.orders')}`}
        enabled={ntfShopOrder}
        setEnabled={setNtfShopOrder}
      />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.contact_request')}`}
        enabled={ntfShopContact}
        setEnabled={setNtfShopContact}
      />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.comment')}`}
        enabled={ntfShopComment}
        setEnabled={setNtfShopComment}
      />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.rating')}`}
        enabled={ntfShopEvaluation}
        setEnabled={setNtfShopEvaluation}
      />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.watcher')}`}
        enabled={ntfWatcher}
        setEnabled={setNtfWatcher}
      />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.report')}`}
        enabled={ntfDashbarReport}
        setEnabled={setNtfDashbarReport}
      />
      <MenuButtonSwitch
        text={`${t('settings.mobile.options.personal.stream')}`}
        enabled={ntfDashbarStream}
        setEnabled={setNtfDashbarStream}
      />
    </PageWrapper>
  )
}

export default Settings
