import { useCallback, useContext } from 'react'
import * as Crypto from 'expo-crypto'
import { SessionContext } from '../../context/SessionContext'
import { IWidget } from '../../types/dashboard'
import getApp from '../../firebase'

const useUpdateWidgets = (dashboardId: string) => {
  const { user } = useContext(SessionContext)
  return useCallback(
    async (widgets: Array<IWidget>) => {
      const newWidgets = widgets.map((widget) => ({
        ...widget,
        id: widget.id ?? Crypto.randomUUID(),
      }))

      return getApp()
        .firestore()
        .collection(`company/${user?.company ?? '-'}/dashboard`)
        .doc(dashboardId)
        .update({
          widgets: newWidgets,
        })
    },
    [dashboardId, user?.company]
  )
}

export default useUpdateWidgets
