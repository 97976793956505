import { StyleSheet } from 'react-native'
import { FONT_SIZE_WEB } from '../../utils/constants'

const useStyles = () => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      padding: 15,
    },
    title: {
      fontSize: FONT_SIZE_WEB,
      fontFamily: 'Comfortaa_400Regular',
    },
  })
}

export default useStyles
