import React, { useContext, useEffect, useState } from 'react'
import { Modal, ScrollView, View, Image, Dimensions, TouchableOpacity, Platform } from 'react-native'
import { SessionContext } from '../../context/SessionContext'
import useSetDocument from '../../hooks/useSetDocument'
import Button from '../Button'
import * as WebBrowser from '../Browser'
import useStyles from './styles'
import firebase from 'firebase/compat/app'
import CouponRow from './CouponRow'
import { useNavigate } from '../Router/Router'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import getApp from '../../firebase'

const InAppPopup = () => {
  const { company, currentUser } = useContext(SessionContext)
  // const popups = useCollection<Record<string, any>>(`popup`, ['active', '==', true])
  const [popups, setPopups] = useState<Array<Record<string, any>>>([])
  const [popupTimeout, setPopupTimeout] = useState<boolean>(false)
  const [checkFinished, _setCheckFinished] = useState<boolean>(true)
  const [showTrial, _setShowTrial] = useState<boolean>(false)
  const [trialBookingId, _setTrialBookingId] = useState<string | null>(null)
  //const match = useRouteMatch()
  const navigate = useNavigate()
  const setPopup = useSetDocument(`popup`)

  useEffect(() => {
    getApp()
      .firestore()
      .collection(`popup`)
      .where('active', '==', true)
      .get()
      .then((res) => {
        const popupArray: Array<Record<string, any>> = []
        res.forEach((popup) => {
          popupArray.push({ ...popup.data(), id: popup.id })
        })
        setPopups(popupArray)
      })
  }, [])

  //Currently no available because coupons dont work
  // useEffect(() => {
  //   firestore
  //     .collection(`company/${company?.id}/booking`)
  //     .where('isTrial', '==', true)
  //     .get()
  //     .then((bookings) => {
  //       if (bookings.size > 0) {
  //         const data = bookings.docs[0].data()
  //         if (
  //           data.endDate.toDate() < new Date(new Date().setDate(new Date().getDate() + 3)) &&
  //           (!data.trialPopupShown || data.trialPopupShown === false)
  //         ) {
  //           setTrialBookingId(bookings.docs[0].id)
  //           setShowTrial(true)
  //         }
  //         setCheckFinished(true)
  //       } else {
  //         setShowTrial(false)
  //         setCheckFinished(true)
  //       }
  //     })
  // }, [company?.id])

  const setTrialPopupShown = (bid: string) => {
    getApp().firestore().doc(`company/${company?.id}/booking/${bid}`).set({ trialPopupShown: true }, { merge: true })
  }

  // const validPopups = Object.entries(popups || {})
  const validPopups = popups
    .filter((v) => {
      const validForBundle = v.validFor.includes(company?.bundle || 'nobundle')
      const used = v.used && v.used.includes(currentUser?.uid || '-')
      const validForAbo =
        v.noAbo === true && v.noAbo !== undefined
          ? company?.aboCloud?.aboId !== undefined && company?.bundle !== 'free'
            ? false
            : true
          : true
      const validForUser =
        v.users && v.users !== undefined && v.users.length > 0 ? v.users.includes(currentUser?.uid) : true

      const validPeriod =
        (v.validFrom ? v.validFrom.toDate().getTime() < new Date().getTime() : true) &&
        (v.validUntil ? v.validUntil.toDate().getTime() > new Date().getTime() : true)

      return validForBundle && !used && validForAbo && validForUser && validPeriod
    })
    .map((v) => {
      return v
    })
    .filter((v, index) => {
      if (showTrial) {
        return v.id === 'trial'
      } else {
        return index === 0
      }
    })
    .reduce<Record<string, any>>((_old, curr, index) => {
      if (index === 0) return curr
      return {}
    }, {})

  const hasCoupon = validPopups.coupon && validPopups.coupon !== null
  const styles = useStyles()

  const onPopupClosed = (id: string) => {
    setPopupTimeout(true)
    if (showTrial) {
      setTrialPopupShown(trialBookingId || '')
    } else {
      setPopup({ used: firebase.firestore.FieldValue.arrayUnion(`${currentUser?.uid}`) }, id)
    }
  }
  useEffect(() => {
    // console.debug(validPopups)
  }, [validPopups])

  // console.log(Object.keys(validPopups).length > 0)
  // console.log(!popupTimeout)
  // console.log(checkFinished)
  return (
    <Modal
      animationType="fade"
      visible={Object.keys(validPopups).length > 0 && !popupTimeout && checkFinished}
      transparent={true}
      onRequestClose={() => {
        onPopupClosed(validPopups?.id)
      }}
    >
      <View style={styles.modalWrapper}>
        <View style={styles.centeredView}>
          <ScrollView style={styles.modal} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <>
              <Image
                {...{
                  height: Dimensions.get('window').width <= 1000 ? 450 : 650,
                  resizeMode: 'cover',
                  source: {
                    uri: Dimensions.get('window').width <= 1000 ? validPopups.imageSmall : validPopups.imageLarge,
                  },
                  alt: 'Not found',
                  style: {
                    height: Dimensions.get('window').width <= 1000 ? 450 : 650,
                    overflow: 'hidden',
                    borderRadius: 15,
                  },
                }}
              />
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  overflow: 'hidden',
                  borderRadius: 15,
                }}
                onPress={() => {
                  if (validPopups?.extern && validPopups.extern === true) {
                    WebBrowser.openUrl(validPopups.actionUrl, '_blank').then(() => {
                      onPopupClosed(validPopups.id)
                    })
                  } else {
                    navigate(validPopups.actionUrl)
                    onPopupClosed(validPopups.id)
                  }
                }}
              >
                <Button style={styles.button} onPress={() => onPopupClosed(validPopups?.id)}>
                  <FontAwesomeIcon
                    style={{ alignSelf: 'center', ...(Platform.OS === 'ios' && { marginTop: 5 }) }}
                    icon={'xmark'}
                  />
                </Button>
                {hasCoupon && <CouponRow couponId={validPopups.coupon} />}
              </TouchableOpacity>
            </>
          </ScrollView>
        </View>
      </View>
    </Modal>
  )
}
export default InAppPopup
