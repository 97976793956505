import { StyleSheet } from 'react-native'
import useIsMobileWebRender from '../../hooks/useIsMobileWebRender'
import colors from '../../constants/colors'
import { usePrimaryBackground } from '../../hooks/useColors'

const useStyles = () => {
  return StyleSheet.create({
    centeredView: {
      //flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    centeredViewNative: {
      //flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    modalWrapperNative: {
      position: 'absolute',
      height: '100%',
      justifyContent: 'flex-end',
      width: '100%',
      zIndex: 30,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.lightBgTransparent7,
    },
    modalWrapper: {
      //position: 'absolute',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
      alignSelf: 'stretch',
      zIndex: 30,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.lightBgTransparent7,
    },
    title: {
      fontSize: 24,
      width: '100%',
      padding: 30,
      textAlign: 'center',
    },
    modal: {
      width: useIsMobileWebRender() ? '100%' : '50%',
      // maxWidth: '50%',
      minWidth: '50%',
      //minHeight: '50%',
      height: useIsMobileWebRender() ? '100%' : '70%',
      borderRadius: 15,
      backgroundColor: usePrimaryBackground(),
      paddingVertical: 30,
      paddingHorizontal: useIsMobileWebRender() ? 0 : 80,
      margin: 20,
    },
    modalNative: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      minHeight: '50%',
      borderRadius: 15,
      backgroundColor: usePrimaryBackground(),
      paddingVertical: 10,
      paddingHorizontal: 10,
      margin: 5,
    },
  })
}

export default useStyles
