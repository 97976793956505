import { TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import { FontAwesomeIcon } from '../../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import icons from '../../../../../../../constants/icons'
import colors from '../../../../../../../constants/colors'
import ColoredText from '../../../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../../../hooks/useTranslation'

const CouponFailure = ({ code, resetCoupon }: { code: string; resetCoupon: () => void }) => {
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <View style={styles.wrapper}>
      <View style={styles.iconWrapper}>
        <FontAwesomeIcon icon={[icons.faIconStyle, 'xmark-circle']} fontSize={24} color={colors.red_800} />
      </View>
      <View style={styles.textWrapper}>
        <ColoredText style={styles.textCode}>{`${t('checkout.coupon.invalid_coupon.title')}`}</ColoredText>
        <ColoredText
          style={styles.textPromo}
        >{`${t('checkout.coupon.invalid_coupon.text', { code: code })}`}</ColoredText>
      </View>
      <View style={styles.buttonWrapper}>
        <TouchableOpacity onPress={resetCoupon}>
          <FontAwesomeIcon icon={[icons.faIconStyle, 'xmark']} color={colors.black} />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default CouponFailure
