import { View } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../context/SessionContext'
import useStyles from './styles'
import colors from '../../constants/colors'
import { useTranslation } from '../../hooks/useTranslation'
import { FontAwesomeIcon } from '../FontAwesome/ColoredFontAwesomeIcon'
import ColoredText from '../CustomText/ColoredText'

import getApp from '../../firebase'

const AttentionNotice = () => {
  const { company } = useContext(SessionContext)
  // const currentBooking = useCurrentBundleBooking()
  const [isMigration, setIsMigration] = useState<boolean>(false)
  // const isMigration = currentBooking?.isMigration || false
  // const company = useDocument<ICompany>(`company`, user?.company)
  const hasAbo = company?.aboCloud?.aboId !== undefined
  const styles = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (company && company.id) {
      getApp()
        .firestore()
        .collection(`company/${company.id}/booking`)
        .get()
        .then((docs) => {
          if (docs.empty) {
            setIsMigration(false)
          } else {
            const bookingDoc = docs.docs[0]
            setIsMigration(bookingDoc.data()?.isMigration ?? false)
          }
        })
    }
  }, [company])

  if (!isMigration || hasAbo) {
    return <></>
  }

  return (
    <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row' }}>
      <FontAwesomeIcon icon={'exclamation-triangle'} color={colors.highlightDark} />
      <ColoredText style={styles.textBold}>
        <>{t('subscription.action_required')}</>
      </ColoredText>
      <ColoredText style={styles.text}>
        <>{t('subscription.action_required_text')}</>
      </ColoredText>
    </View>
  )
}

export default AttentionNotice
