import { View } from 'native-base'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ColoredText from '../../../../../../components/CustomText/ColoredText'

const ReleaseInfo = () => {
  const { t } = useTranslation()
  return (
    <View style={{ alignItems: 'center', margin: 10, paddingVertical: 5 }}>
      <ColoredText>
        <>{t('app.version', { version: `${require('../../../../../../../package.json').version}` })}</>
      </ColoredText>
    </View>
  )
}

export default ReleaseInfo
