import React, { useContext, useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import useStyles from './styles'
import ComponentWrapper from '../../../../../../components/ComponentWrapper'
import { SessionContext } from '../../../../../../context/SessionContext'
import BillRow from './components/BillRow'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import getApp from '../../../../../../firebase'

const Bills = () => {
  const { company } = useContext(SessionContext)
  const styles = useStyles()
  // const bills = useCollection<Record<string, any>>(`company/${company?.id}/bills`, undefined, ['created', 'desc'])
  const { t } = useTranslation()
  const [limit, setLimit] = useState<number>(10)

  const increaseLimit = () => setLimit(limit + 10)
  const [bills, setBills] = useState<Array<Record<string, any>> | null>(null)

  useEffect(() => {
    if (company && company.id) {
      getApp()
        .firestore()
        .collection(`company/${company?.id}/bills`)
        .orderBy(`created`, `desc`)
        .get()
        .then((docs) => {
          if (docs.empty) {
            setBills(null)
          } else {
            const billArray: Array<Record<string, any>> = []
            docs.forEach((doc) => {
              billArray.push({ ...doc.data(), id: doc.id })
            })
            setBills(billArray)
          }
        })
    }
  }, [company])

  return (
    <ComponentWrapper title={t('accountOverview.bills.your_bills')}>
      <View style={styles.wrapper}>
        <View style={styles.contentWrapper}>
          {bills &&
            bills
              .filter((_, index) => {
                return index < limit
              })
              .map((v) => {
                return <BillRow invoice={v} key={v.id} />
              })}
          {bills && Object.keys(bills).length > limit && (
            <TouchableOpacity onPress={increaseLimit}>
              <ColoredText>
                <>{t('accountOverview.bills.load_more')}</>
              </ColoredText>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </ComponentWrapper>
  )
}

export default Bills
