import { useCallback, useContext } from 'react'
import { SessionContext } from '../../context/SessionContext'
import getApp from '../../firebase'

const useStoreNotificationSettings = () => {
  const { currentUser } = useContext(SessionContext)

  return useCallback(async (settings: Record<string, any>) => {
    await getApp()
      .firestore()
      .doc(`user/${currentUser?.uid}`)
      .set({ preferences: { notifications: settings } }, { merge: true })
  }, [])
}

export default useStoreNotificationSettings
