import React, { useContext, useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import {
  currencyToSymbol,
  dashboardsForBundle,
  notificationsForBundle,
  shopsForBundle,
  userForBundle,
} from '../../../../../../utils/booking'
import useDocument from '../../../../../../hooks/useDocument'
import useStyles from './styles'
import Button from '../../../../../../components/Button'
import colors from '../../../../../../constants/colors'
import { SessionContext } from '../../../../../../context/SessionContext'
import Pro from '../../../../../../assets/imgs/subscription/Pro'
import Business from '../../../../../../assets/imgs/subscription/Business'
import Free from '../../../../../../assets/imgs/subscription/Free'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import ColoredText from '../../../../../../components/CustomText/ColoredText'
import { FontAwesomeIcon } from '../../../../../../components/FontAwesome/ColoredFontAwesomeIcon'
import { IBundle, Interval } from '../../../../../../types/booking'
import { useNavigate } from '../../../../../../components/Router/Router'
import getApp from '../../../../../../firebase'

const BundleCard = ({ bundle }: { bundle: IBundle }) => {
  const bundleDoc = useDocument<Record<string, any>>('bundle', bundle)
  const styles = useStyles()
  const { userIsAdmin, company } = useContext(SessionContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isStillActive, setIsStillActive] = useState<boolean>(true)
  const [{ interval }, setIntervalState] = useState<{
    interval: Interval
  }>({
    interval: Interval.year,
  })

  useEffect(() => {
    getApp()
      .firestore()
      .collection(`company/${company?.id}/booking`)
      .get()
      .then((bookings) => {
        if (bookings.empty) {
          setIsStillActive(false)
          return
        }
        const bookingData = bookings.docs[0].data()
        if (bookingData?.status === 1 || bookingData?.endDate?.toDate() > new Date()) {
          setIsStillActive(true)
        } else {
          setIsStillActive(false)
        }
      })
  }, [])

  return (
    <>
      <View style={styles.wrapper}>
        <View
          style={[
            styles.titleWrapper,
            bundle === IBundle.free
              ? styles.backgroundFree
              : bundle === IBundle.pro || bundle === IBundle.enterprise
                ? styles.backgroundPro
                : styles.backgroundBusiness,
          ]}
        >
          <ColoredText style={styles.title}>{`dash.bar ${bundle || IBundle.free}`}</ColoredText>
          <View>
            {bundle === IBundle.pro || bundle === IBundle.enterprise ? (
              <Pro />
            ) : bundle === IBundle.business ? (
              <Business />
            ) : (
              <Free />
            )}
          </View>
        </View>
        <View style={styles.contentWrapper}>
          <View style={styles.bundleOptionsWrapper}>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{userForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{shopsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{dashboardsForBundle(bundle)}</ColoredText>
            </View>
            <View style={styles.itemWrapper}>
              <FontAwesomeIcon icon={'check'} color={colors.success} />
              <ColoredText style={styles.itemText}>{notificationsForBundle(bundle)}</ColoredText>
            </View>
          </View>
          {bundle !== IBundle.free && isStillActive === false ? (
            <>
              <View style={styles.divider} />
              <View style={styles.amountWrapper}>
                <TouchableOpacity
                  style={[styles.intervalCard, interval === Interval.month ? styles.selected : null]}
                  onPress={() => setIntervalState({ interval: Interval.month })}
                >
                  <View style={styles.intervalWrapper}>
                    <ColoredText style={styles.amountInterval}>
                      <>{t('subscription.interval.text_month')}</>
                    </ColoredText>
                    <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.month?.value}${currencyToSymbol(
                      bundleDoc?.price?.month?.currency
                    )}`}</ColoredText>
                    {interval === Interval.month ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.intervalCard, interval === Interval.year ? styles.selected : null]}
                  onPress={() => setIntervalState({ interval: Interval.year })}
                >
                  <View style={styles.intervalWrapper}>
                    <ColoredText style={styles.amountInterval}>
                      <>{t('subscription.interval.text_year')}</>
                    </ColoredText>
                    <ColoredText style={styles.amountValue}>{`${bundleDoc?.price?.year?.value}${currencyToSymbol(
                      bundleDoc?.price?.year?.currency
                    )}`}</ColoredText>
                    {interval === Interval.year ? <FontAwesomeIcon icon={'check'} color={colors.success} /> : null}
                    {interval === Interval.year ? (
                      <View
                        style={{
                          backgroundColor: colors.error,
                          borderRadius: 15,
                          padding: 10,
                          marginLeft: 20,
                          justifyContent: 'center',
                        }}
                      >
                        <ColoredText style={{ color: 'white' }}>
                          <>{t('subscription.promote_text.discount_' + bundle)}</>
                        </ColoredText>
                      </View>
                    ) : null}
                  </View>
                </TouchableOpacity>

                <View style={styles.buttonWrapper}>
                  {userIsAdmin ? (
                    <Button
                      style={styles.button}
                      textStyle={{ color: 'white' }}
                      onPress={() => {
                        navigate(`/settings/subscription/checkout/${bundle}/${interval}`)
                      }}
                    >
                      <>{t('subscription.choose')}</>
                    </Button>
                  ) : (
                    <ColoredText>
                      <>{t('subscription.only_admins')}</>
                    </ColoredText>
                  )}
                </View>
              </View>
            </>
          ) : (
            <View style={styles.amountWrapper}>
              {bundle !== IBundle.free && (
                <ColoredText style={{ marginVertical: 20 }}>
                  <> {t('subscription.booking_not_possible.active_booking_exists')}</>
                </ColoredText>
              )}
            </View>
          )}
        </View>
      </View>
    </>
  )
}

export default BundleCard
